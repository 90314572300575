import React from "react";
import { Link } from "react-router-dom";
import mark from "./../../../check-mark-error.png";

export const Error = () => {
  return (
    <div className={"sectionSuccess"}>
      <div className={"forgotBox"}>
        <span className={"spanWelcome"}>Ваша оплата не прошла</span>
        <img src={mark} alt="Ошибка" />
        <Link style={{fontWeight: 'bold', width: '100%'}} className={"batonPay"} to={"/"}>
          Попробовать снова
        </Link>
      </div>
    </div>
  );
};
