import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { emailValidation } from "../../validations/validationRegistrationForm";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../redux-store/store";
import { forgotPasswordThunk } from "../../redux-store/registration-login-auth";
import "./Forgot.scss";
import { CircularProgress, createTheme, Theme, ThemeProvider } from "@mui/material";
import { statusAC } from "../../redux-store/error-wait-reducer";
import { useTheme } from "@mui/material/styles";
import mark from "../../check-mark-success.png";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
      primary: {
        main: "#fff"
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            color: "white",
            "--TextField-brandBorderColor": "white",
            "--TextField-brandBorderHoverColor": "white",
            "--TextField-brandBorderFocusedColor": "white",
            "& label.Mui-focused": {
              color: "white",
              fontFamily: "Golos"
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: "white",
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)"
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)"
            },
            fontFamily: "Golos"
          }
        }
      }
    }
  });

interface FormRegistration {
  email: string;
}

export const ForgotPassword = () => {
  const outerTheme = useTheme();
  const status = useAppSelector((state) => state.errorReducer.status);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors, isValid },
    register
  } = useForm<FormRegistration>({
    mode: "all"
  });
  const onSubmit: SubmitHandler<FormRegistration> = (data) => {
    dispatch(
      forgotPasswordThunk({
        email: data.email.toLowerCase()
      })
    );
  };

  useEffect(() => {
    dispatch(statusAC({ status: "idle" }));
  }, []);

  return (
    <div className={"forgot"}>
      {status === "loading" ? (
        <CircularProgress style={{color: 'white'}} />
      ) : status === "success" ? (
        <div className={"forgotBox"}>
          <span className={"spanTarifActive"}>
            На Вашу почту была успешно <br /> отправлена сслыка на изменение
            пароля
          </span>
          <img src={mark} alt="Успешно" />
        </div>
      ) : (
        <form className={"forgotBox"} onSubmit={handleSubmit(onSubmit)}>
          <span className={"spanTarifActive"}>
            Введите свою почту <br /> для отправки сслыки на изменеие пароля
          </span>
          <div className={"divLogin"}>
            <ThemeProvider theme={customTheme(outerTheme)}>
              <TextField
                InputLabelProps={{ style: { color: "white" } }}
                className={"forgotEmail"}
                {...register("email", emailValidation)}
                label="Email"
                multiline
                variant="standard"
                error={!!errors.email?.message}
                helperText={errors.email?.message}
              />
            </ThemeProvider>
          </div>
          {!errors.email?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> : undefined}
          <button
            style={{ padding: "5px 40px", marginTop: 30 }}
            className={`batonStandart + ' ' + ${!isValid ? "dis" : ""}`}
            disabled={!isValid}
            type={"submit"}
          >
            Отправить
          </button>
        </form>
      )}
    </div>
  );
};
