import React from "react";
import "./Success.scss";
import mark from "./../../../check-mark-success.png";
import { HashLink as Anchor } from "react-router-hash-link";

export const Success = () => {
  return (
    <div className={"sectionSuccess"}>
      <div className={"forgotBox"}>
        <span className={"spanWelcome"}>Ваша оплата прошла успешно</span>
        <img src={mark} alt="Успешно" />
        <Anchor smooth to={"/#sectionDateScroll"} style={{fontWeight: 'bold', width: '100%'}} className={"batonPay"}>
          Перейти к рассчету матрицы
        </Anchor>
      </div>
    </div>
  );
};
