import React from "react";
import { useAppSelector } from "../../../../redux-store/store";
import "./Orders.scss";
import { v4 } from "uuid";

export const Orders = () => {
  const orders = useAppSelector(
    (state) => state.registrationReducer.historyOfOrders
  );





  const historyOrders = orders.map((el) => {
    const outputString = el.price
      ?.toString()
      .replace(/(\d+)00(?=\s|$)/g, "$1,00");

    return (
      <tr key={v4()}>
        <td>{el.name}</td>
        <td>{el.orderId}</td>
        <td>{el.date.replace(/(\d+)-(\d+)-(\d+)/, (_, year, month, day) => `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`)}</td>
        <td>{`${outputString} руб.`}</td>
      </tr>
    );
  });

  return (
    <div className={"userProfile"}>
       <span className={"spanTarifActive"}>
            История заказов
          </span>
      {orders.length ? (
        <div className={"ordersDiv"}>
          <table className={'tableOrders'}>
            <thead>
            <tr>
              <th>Название</th>
              <th>Номер заказа</th>
              <th>Дата покупки</th>
              <th>Стоимость</th>
            </tr>
            </thead>
            <tbody>
            {historyOrders}
            </tbody>
          </table>
        </div>
      ) : (
          <span className={"spanHistoryNone"}>Ваша история заказов пуста</span>
      )}
    </div>
  );
};
