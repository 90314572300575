import React from "react";
import "./Method.scss";
import bigStar from "../big-star.png";
import smallStar from "../small-star.png";

export const Method = () => {
  return (
    <div id={"methodScroll"} className={"methodDiv"}>
      <div className={"backImagePlanets"}></div>
      <div className={"methodFlex"}>
        <div className={"methodDescription"}>
          <div className={"methodDesc"}>
            <span className={"methodSpan"}>О МЕТОДЕ</span>
            <p>Эта система самопознания помогает определить вашу индивидуальность
              через анализ личности. Она служит инструментом для раскрытия
              внутренних ресурсов и достижения гармонии. Получение знаний о энергиях
              вашей Матрицы Судьбы помогает вам лучше понять себя, улучшить все
              сферы жизни.</p>
          </div>
          <div className={"pngStar"}>
            <img src={bigStar} alt="Большая звезда" />
            <img src={smallStar} alt="Маленькая звезда" />
          </div>
        </div>
        <div className={'iconFour'}>
          <div>
            <span>1</span>
            <p>Освободитесь от негативных <br/> эмоций и опыта прошлого
            </p>
          </div>
          <div>
            <span>2</span>
            <p>Узнайте особенности <br/> вашего характера</p>
          </div>
          <div>
            <span>3</span>
            <p>Разберитесь в причинах <br/> проблем со здоровьем
            </p>
          </div>
          <div>
            <span>4</span>
            <p>Направьте ваши ресурсы <br/> в нужное русло
            </p>
          </div>
        </div>
        <span className={'spanPurpose'}>поймите свое истинное <br/> предназначение</span>
      </div>
    </div>
  );
};
