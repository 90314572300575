import React from "react";
import "./Success.scss";
import { Link, useLocation } from "react-router-dom";
import mark from "./../../../check-mark-success.png";
import { useAppSelector } from "../../../redux-store/store";

export const SuccessDateOnce = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const matrix = queryParams.get("matrix");
  const date = queryParams.get("date");
  const name = queryParams.get("name");
  const gender = queryParams.get("gender");
  const date1 = queryParams.get("date1");
  const date2 = queryParams.get("date2");
  const id = useAppSelector((state) => state.registrationReducer.id);
  return (
    <div className={"sectionSuccess"}>
      <div className={"forgotBox"}>
        <span className={"spanWelcome"}>Ваша оплата прошла успешно</span>
        <img src={mark} alt="Успешно" />
        {matrix === "compatibility" ? (
          <Link
            className={"batonPay"}
            style={{fontWeight: 'bold', width: '100%'}}
            to={`/matrixcompatibility?date=${date1}&date1=${date2}&id=${id}&repeat=true`}
          >
            Перейти к приобретенной дате
          </Link>
        ) : (
          <Link
            aria-disabled
            style={{fontWeight: 'bold', width: '100%'}}
            className={"batonPay"}
            to={`/matrix?date=${date}&male=${gender}&id=${id}&name=${name}&child=${matrix !== "personal" ? 'true' : ''}&repeat=true`}
          >
            Перейти к приобретенной дате
          </Link>
        )}
      </div>
    </div>
  );
};
