import React, { ChangeEvent } from "react";
import "./User.scss";
import { useAppDispatch, useAppSelector } from "../../../../redux-store/store";
import {
  updateUserThunk,
  uploadAvatarThunk
} from "../../../../redux-store/registration-login-auth";
import imageCompression from "browser-image-compression";
import { CircularProgress, createTheme, Skeleton, Theme, ThemeProvider } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {
  emailValidation,
  nameAndSurnameValidation,
  passValidationUser,
  phoneValidation
} from "../../../../validations/validationRegistrationForm";
import { MuiTelInput } from "mui-tel-input";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import FormHelperText from "@mui/material/FormHelperText";
import { avatarWaitAC } from "../../../../redux-store/error-wait-reducer";
import { useTheme } from "@mui/material/styles";
import iconAvatar from '../../../../profile-img.png'
const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            color: 'white',
            "--TextField-brandBorderColor": "white",
            "--TextField-brandBorderHoverColor": "white",
            "--TextField-brandBorderFocusedColor": "white",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)"
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: 'white',
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)"
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)"
            },
            fontFamily: 'Golos'
          }
        }
      }
    }
  });
interface FormRegistration {
  name: string;
  surname: string;
  email: string;
  password: string;
  confirmpass: string;
  phoneInput: string;
}
export const User = () => {
  const outerTheme = useTheme();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.registrationReducer.id);
  const avatar = useAppSelector((state) => state.registrationReducer.avatar);
  const avatarWait = useAppSelector((state) => state.errorReducer.avatarWait);
  const name = useAppSelector((state) => state.registrationReducer.name);
  const surname = useAppSelector((state) => state.registrationReducer.surname);
  const email = useAppSelector((state) => state.registrationReducer.email);
  const updateUser = useAppSelector((state) => state.errorReducer.updateUser);
  const phoneNumber = useAppSelector(
    (state) => state.registrationReducer.phone
  );
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(avatarWaitAC({ avatarWait: true }));
    let file = event.target.files?.[0];
    if (!file) return;
    try {
      const compressedImage = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      });

      const reader = new FileReader();
      reader.readAsDataURL(compressedImage);
      reader.onload = () => {
        const base64Image = reader.result?.toString().split(",")[1];
        if (base64Image) {
          dispatch(
            uploadAvatarThunk({
              avatar: `data:image/jpeg;base64,${base64Image}`,
              userId
            })
          );
        }
      };
      event.target.value = "";
    } catch (error) {
      console.log(error);
    }
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    control,
    reset
  } = useForm<FormRegistration>({
    mode: "onBlur"
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const password = getValues("password");
  const onSubmit: SubmitHandler<FormRegistration> = (data) => {
    if (data.password !== "") {
      dispatch(
        updateUserThunk({
          userId,
          name: data.name,
          surname: data.surname,
          email: data.email,
          phone: data.phoneInput,
          password: data.password
        })
      );
      reset({ password: "", confirmpass: "" });
    } else {
      dispatch(
        updateUserThunk({
          userId,
          name: data.name,
          surname: data.surname,
          email: data.email,
          phone: data.phoneInput
        })
      );
    }
  };
  return (
    <div className={"userProfile"}>
      <div className={"divUserFlex"}>
        <div className={"divAvatar"}>
          {avatarWait ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%"
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <img
              className={"avatarImg"}
              src={
                avatar
                  ? avatar
                  : iconAvatar
              }
              width={400}
              alt="Профиль"
            />
          )}
          {avatarWait ? (
            ""
          ) : (
            <input
              className={"avatarInput"}
              onChange={handleFileUpload}
              accept="image/jpeg,image/png,image/gif"
              type="file"
            />
          )}
        </div>
        <div className={"divSpanUser"}>
          <span className={"spanUserName"}>
            {updateUser ? (
              <Skeleton style={{ width: 200 }} />
            ) : (
              name + " " + surname
            )}
          </span>
          <span className={"spanUserEmail"}>
            {updateUser ? <Skeleton style={{ width: 200 }} /> : email}
          </span>
        </div>
      </div>
      <form className={"formUpdate"} onSubmit={handleSubmit(onSubmit)}>
        <div className={"divUserForm"}>
          <span className={'spanUser'}>Имя</span>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              className={"formPassAndMail"}
              {...register("name", nameAndSurnameValidation)}
              multiline
              maxRows={4}
              defaultValue={name}
              variant="standard"
              error={!!errors.name?.message}
              helperText={errors.name?.message}
            />
          </ThemeProvider>
        </div>
        <div className={"divUserForm"}>
          <span className={"spanUser"}>Фамилия</span>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              className={"formPassAndMail"}
              {...register("surname", nameAndSurnameValidation)}
              defaultValue={surname}
              multiline
              maxRows={4}
              variant="standard"
              error={!!errors.surname?.message}
              helperText={errors.surname?.message}
            />
          </ThemeProvider>
        </div>
        <div className={"divUserForm"}>
          <span className={"spanUser"}>Телефон</span>
          <Controller
            control={control}
            rules={phoneValidation}
            defaultValue={phoneNumber}
            name={"phoneInput"}
            render={({ field, fieldState }) => (
              <ThemeProvider theme={customTheme(outerTheme)}>
                <MuiTelInput
                  {...field}
                  className={"formPassAndMail"}
                  onlyCountries={["RU", "KZ", "BY", "AZ", "AM", "KG", "MD", "TJ", "TM", "UZ", "UA"]}
                  defaultCountry={"RU"}
                  variant="standard"
                  inputProps={{ maxLength: 20 }}
                  helperText={
                    fieldState.invalid ? errors.phoneInput?.message : ""
                  }
                  error={fieldState.invalid}
                /></ThemeProvider>
            )}
          ></Controller>
        </div>
        <div className={"divUserForm"}>
          <span className={"spanUser"}>Почта</span>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              {...register("email", emailValidation)}
              className={"formPassAndMail"}
              defaultValue={email}
              multiline
              maxRows={4}
              variant="standard"
              error={!!errors.email?.message}
              helperText={errors.email?.message}
            />
          </ThemeProvider>
        </div>
        <div className={"divUserForm"}>
          <span className={"spanUser"}>Новый пароль</span>
          <FormControl className={"formPassAndMail"}>
            <ThemeProvider theme={customTheme(outerTheme)}>
              <TextField
                variant={"standard"}
                error={!!errors.password?.message}
                {...register("password", passValidationUser)}
                id="standart-adornment-password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      sx={{ color: "white" }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </ThemeProvider>
            <FormHelperText error={!!errors.password?.message}>
              {errors.password?.message}
            </FormHelperText>
          </FormControl>
        </div>
        <div className={"divUserForm"}>
          <span className={"spanUser"}>Повторите пароль</span>
          <FormControl className={"formPassAndMail"}>
            <ThemeProvider theme={customTheme(outerTheme)}>
              <TextField
                id="standart-adornment-amount"
                error={!!errors.confirmpass?.message}
                {...register("confirmpass", {
                  validate: {
                    passConf: (value: string) => {
                      if (password) {
                        if (password !== value) {
                          return "Пароли не совпадают";
                        }
                      }
                      return true;
                    }
                  }
                })}
                variant={"standard"}
                type={showPassword1 ? "text" : "password"}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      sx={{ color: "white" }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </ThemeProvider>
            <FormHelperText error={!!errors.confirmpass?.message}>
              {errors.confirmpass?.message}
            </FormHelperText>
          </FormControl>
        </div>
        <button
          style={{ padding: "10px 70px", marginTop: 30, fontWeight: 'bold', fontSize: '16px' }}
          className={`baton`}
          type={"submit"}
        >
          Сохранить изменения
        </button>
      </form>
    </div>
  );
};
