import React, { useState, useEffect } from "react";
import "./Learn.scss";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../redux-store/store";
import { v4 } from "uuid";
import { HashLink as Anchor } from "react-router-hash-link";

const checkSubscription = (subscribe: []) => {
  return subscribe.filter(
    (el: any) =>
      [
        "Базовый курс + Вместе и навсегда",
        "Базовый курс матрицы",
        "Курс детской матрицы",
        "Курс матрицы совместимости",
        "Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца"
      ].includes(el.subscribe) && el.access === true
  );
};

export const Learn = () => {
  const subscribe = useAppSelector(
    (state) => state.registrationReducer.subscription
  );
  const [set, setState] = useState(false);

  useEffect(() => {
    setState(checkSubscription(subscribe).length > 0);
  }, [subscribe]);

  const learnSubArr = checkSubscription(subscribe);

  const uniqueArray: any[] = [];
  learnSubArr.forEach((item: any) => {
    const existingItem = uniqueArray.find(
      (uniqueItem) =>
        uniqueItem.subscribe === item.subscribe &&
        uniqueItem.access === item.access
    );
    if (!existingItem) {
      uniqueArray.push(item);
    }
  });

  const learnSub = uniqueArray.map((el: any) => {
    let linkUrl = "";
    switch (el.subscribe) {
      case "Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца":
        linkUrl = "https://t.me/+F6F3J5ai-8tjZWQ6";
        break;
      case "Базовый курс матрицы":
        linkUrl = "https://t.me/+Fmih-h4PnEVhYjAy";
        break;
      case "Базовый курс + Вместе и навсегда":
        linkUrl = "https://t.me/+Fmih-h4PnEVhYjAy";
        break;
      case "Курс детской матрицы":
        linkUrl = "https://t.me/+V1yi-XaJqzMxNjgy";
        break;
      default:
        linkUrl = "https://t.me/+tLTrL-tHdOg3Y2Ey";
        break;
    }

    return (
      <div className={"subLearnSpan"} key={v4()}>
        <span>{el.subscribe}</span>
        <div className={"divLearn"}>
          <Anchor
            className={"batonTarif"}
            smooth
            to={"/#tarif"}
            style={{ width: "100%" }}
          >
            Посмотреть программу
          </Anchor>
          <Link
            className={"batonTarif"}
            to={linkUrl}
            style={{ width: "100%", backgroundColor: "rgba(196, 157, 214, 1)", color: "rgba(46, 32, 81, 1)" }}
          >
            Перейти к обучению
          </Link>
        </div>
      </div>
    );
  });

  return (
    <div className={"userProfile"}>
          <span className={"spanTarifActive"}>
            Видеообучение
          </span>
      {!set ? (
        <>
          <span className={"spanHistoryNone"}>
            Вы можете приобрести курс по обучению расшифровки матрицы судьбы
          </span>
          <Link
            style={{
              margin: "0",
              backgroundColor: "rgba(196, 157, 214, 1)",
              color: "rgba(46, 32, 81, 1)",
              padding: "10px 50px 10px 50px"
            }}
            className={"batonTarif"}
            to={"/learn"}
          >
            Приобрести обучение
          </Link>
        </>
      ) : (
        <div className={"learnMenu"}>
          {learnSub}
        </div>
      )}
    </div>
  );
};
