import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux-store/store";
import {
  getMatrixCompatibilityAdmin,
  isDescription,
  updateMatrixCompatibilityAdmin
} from "../../redux-store/get-matrix-compatibility";
import "./MatrixCompatibilityAdmin.scss";
import InputLabel from "@mui/material/InputLabel";
import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import ListSubheader from "@mui/material/ListSubheader";

type MatrixCompatibilityAdminType =
  | "isWhyDidYouMeet"
  | "isTheSpiritualEssenceOfTheCouple"
  | "isMaterialKarma"
  | "isCouplesSpiritualKarma"
  | "isGenericTasksOfPartners"
  | "isSellingMoneyInPairs"
  | "isStrengtheningCoupleRelationships"
  | 'isCoupleTasksToWorkThroughInRelationships'
  | 'isTasksForTheRealizationOfACoupleInSociety'
  | 'isCoupleResource'
  | 'isCoupleComfortZone';

export const MatrixCompatibilityAdmin = () => {
  const id = useAppSelector((state) => state.getMatrixCompatibility.id);
  const data = useAppSelector((state) => state.getMatrixCompatibility.data);
  const [selectMatrix, setSelectMatrix] =
    useState<MatrixCompatibilityAdminType>("isWhyDidYouMeet");
  const [num, setNum] = useState<number>(0);
  const [txt, setTxt] = useState<string>("");
  const userAdminWait = useAppSelector((state) => state.errorReducer.userAdmin);
  const dispatch = useAppDispatch();
  const [nameCategory, setNameCategory] = useState("1. Для чего встретились");
  const [category, setCategory] = useState("isWhyDidYouMeet");
  const handleChange = (event: SelectChangeEvent) => {
    setSelectMatrix(event.target.value as MatrixCompatibilityAdminType);
    if(event.target.value === 'isWhyDidYouMeet'){
      setNameCategory("1. Для чего встретились")
      setCategory('isWhyDidYouMeet')
    } else if(event.target.value === 'isTheSpiritualEssenceOfTheCouple'){
      setNameCategory("2. Духовная суть пары")
      setCategory('isTheSpiritualEssenceOfTheCouple')
    } else if(event.target.value === 'isMaterialKarma'){
      setNameCategory("3. Материальная карма")
      setCategory('isMaterialKarma')
    }else if(event.target.value === 'isCouplesSpiritualKarma'){
      setNameCategory("4. Духовная карма пары")
      setCategory('isCouplesSpiritualKarma')
    }else if(event.target.value === 'isGenericTasksOfPartners'){
      setNameCategory("5. Родовые задачи партнеров")
      setCategory('isGenericTasksOfPartners')
    }else if(event.target.value === 'isSellingMoneyInPairs'){
      setNameCategory("6. Реализация денег в паре")
      setCategory('isSellingMoneyInPairs')
    }else if(event.target.value === 'isStrengtheningCoupleRelationships'){
      setNameCategory("7. Укрепление отношений в паре")
      setCategory('isStrengtheningCoupleRelationships')
    }else if(event.target.value === 'isCoupleTasksToWorkThroughInRelationships'
    || event.target.value === 'isTasksForTheRealizationOfACoupleInSociety'
    || event.target.value === 'isCoupleResource'){
      setNameCategory("8. Предназначение пары")
      setCategory('isThePurposeOfTheCouple')
    } else if(event.target.value === 'isCoupleComfortZone'){
      setNameCategory("9. Зона комфорта пары")
      setCategory('isCoupleComfortZone')
    }
  };

  const onChangeTxt = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTxt(e.currentTarget.value);
  };

  const onClickHandler = (
    index: number,
    description: string,
    id: string,
    name: string
  ) => {
    setNum(0);
    dispatch(updateMatrixCompatibilityAdmin({ index, name, id, description, category }));
  };

  const onDoubleClickChange = (value: number, text: string) => {
    setTxt(text);
    setNum(value);
  };

  useEffect(() => {
    dispatch(getMatrixCompatibilityAdmin({ id }));
  }, []);
  const matrixDescription = (value: isDescription[]) => {
    return value.map((e, index) => {
      return (
        <div className={"divDesc"} key={index}>
          <div className={"spanValue"}>{`Энергии: ${" " + e.value}`}</div>
          {num === e.value ? (
            <React.Fragment>
              <textarea
                style={{ width: "100%", height: "auto" }}
                cols={30}
                rows={10}
                value={txt.split("\n").join("\n")}
                onChange={onChangeTxt}
              ></textarea>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => onClickHandler(e.value, txt, id, selectMatrix)}
                variant={"contained"}
              >
                Изменить
              </Button>
              <Button onClick={() => setNum(0)} variant={"contained"}>
                Отмена
              </Button>
            </React.Fragment>
          ) : (
            <p
              onDoubleClick={() =>
                onDoubleClickChange(e.value, e.text.split("\n").join("\n"))
              }
              className={"descriptionAdmin"}
            >
              <React.Fragment key={index}>
                {e.text.split("\n").map((paragraph: string, innerIndex) => (
                  <React.Fragment key={innerIndex}>
                    {paragraph}
                    <br />
                  </React.Fragment>
                ))}
                <br />
              </React.Fragment>
            </p>
          )}
        </div>
      );
    });
  };

  let table;
  if (selectMatrix === "isWhyDidYouMeet") {
    table = matrixDescription(data.isWhyDidYouMeet);
  } else if (selectMatrix === "isTheSpiritualEssenceOfTheCouple") {
    table = matrixDescription(data.isTheSpiritualEssenceOfTheCouple);
  } else if (selectMatrix === "isMaterialKarma") {
    table = matrixDescription(data.isMaterialKarma);
  } else if (selectMatrix === "isCouplesSpiritualKarma") {
    table = matrixDescription(data.isCouplesSpiritualKarma);
  } else if (selectMatrix === "isGenericTasksOfPartners") {
    table = matrixDescription(data.isGenericTasksOfPartners);
  } else if (selectMatrix === "isSellingMoneyInPairs") {
    table = matrixDescription(data.isSellingMoneyInPairs);
  } else if (selectMatrix === "isCoupleTasksToWorkThroughInRelationships") {
    table = matrixDescription(data.isThePurposeOfTheCouple.isCoupleTasksToWorkThroughInRelationships);
  }else if (selectMatrix === "isTasksForTheRealizationOfACoupleInSociety") {
    table = matrixDescription(data.isThePurposeOfTheCouple.isTasksForTheRealizationOfACoupleInSociety);
  }else if (selectMatrix === "isCoupleResource") {
    table = matrixDescription(data.isThePurposeOfTheCouple.isCoupleResource);
  }else if (selectMatrix === "isStrengtheningCoupleRelationships") {
    table = matrixDescription(data.isStrengtheningCoupleRelationships);
  }else if (selectMatrix === "isCoupleComfortZone") {
    table = matrixDescription(data.isCoupleComfortZone);
  }

  if (userAdminWait) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          top: "50%",
          textAlign: "center",
          width: "100%"
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <FormControl style={{ width: 300, marginBottom: 50 }}>
        <InputLabel id="demo-simple-select">
          {nameCategory}
        </InputLabel>
        <Select
          labelId="demo-simple-select"
          id="demo-simple-select"
          label={nameCategory}
          value={selectMatrix}
          onChange={handleChange}
        >
          <MenuItem value={"isWhyDidYouMeet"}>1. Для чего встретились</MenuItem>
          <MenuItem value={"isTheSpiritualEssenceOfTheCouple"}>
            2. Духовная суть пары
          </MenuItem>
          <MenuItem value={"isMaterialKarma"}>3. Материальная карма</MenuItem>
          <MenuItem value={"isCouplesSpiritualKarma"}>
            4. Духовная карма пары
          </MenuItem>
          <MenuItem value={"isGenericTasksOfPartners"}>
            5. Родовые задачи партнеров
          </MenuItem>
          <MenuItem value={"isSellingMoneyInPairs"}>6. Реализация денег в паре</MenuItem>
          <MenuItem value={"isStrengtheningCoupleRelationships"}>7. Укрепление отношений в паре</MenuItem>
          <ListSubheader data-group={"isThePurposeOfTheCouple"}>
            8. Предназначение пары
          </ListSubheader>
          <MenuItem value={"isCoupleTasksToWorkThroughInRelationships"}>
            -Задачи пары для проработки в отношениях
          </MenuItem>
          <MenuItem value={"isTasksForTheRealizationOfACoupleInSociety"}>
            -Задачи для реализации пары в социуме
          </MenuItem>
          <MenuItem value={"isCoupleResource"}>
            -Ресурс пары
          </MenuItem>
          <MenuItem value={"isCoupleComfortZone"}>
            9. Зона комфорта пары
          </MenuItem>
        </Select>
      </FormControl>
      <div>
        <br />
        {userAdminWait ? (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              height: "100vh",
              top: "40%",
              textAlign: "center",
              width: "100%"
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          table
        )}
        <br />
      </div>
    </div>
  );
};
