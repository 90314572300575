import React from "react";
import "./Konf.scss";
import { Link } from "react-router-dom";

export const Konf = () => {
  return (
    <div className={"divOfera"}>
      <span className={"spanWelcome"}>Политика конфиденциальности</span>
      <span className={"spanOferta"}>1. ОБЩИЕ ПОЛОЖЕНИЯ</span>
      <p>
        1.1. Настоящий документ: «Политика в отношении обработки и защиты
        персональных данных пользователей сервиса (далее – Политика) разработан
        в соответствии со{" "}
        <Link
          style={{color: 'white'}}
          to={
            "https://www.consultant.ru/document/cons_doc_LAW_61801/eeeebe22bf738fd65bb66b95cc278911ae2525ee/"
          }
        >
          статьей 18.1 Федерального закона от 27.07.2006 года № 152-ФЗ «О
          персональных данных»
        </Link>{" "}
        и является основным внутренним документом Администрации сайта (далее –
        Оператор), регулирующим деятельность в области обработки и защиты
        персональных данных.
        <br />
        <br />
        1.2. Политика разработана в целях реализации требований законодательства
        Российской Федерации в области обработки и защиты персональных данных и
        направлена на обеспечение защиты прав и свобод человека и гражданина при
        обработке его персональных данных Оператором, в том числе защиты прав на
        неприкосновенность частной жизни, личной и семейной тайн.
        <br />
        <br />
        1.3. Политика определяет состав получаемых и обрабатываемых персональных
        данных, цели сбора и обработки персональных данных, порядок хранения и
        передачи персональных данных, а также реализуемые Оператором меры,
        направленные на защиту персональных данных.
        <br />
        <br />
        1.4. Действие настоящей Политики распространяется на любую информацию о
        пользователе, полученную Оператором, как до, так и после утверждения
        Политики.
        <br />
        <br />
        1.5. Действие настоящей Политики распространяется на всю информацию,
        которую Оператор может получить о пользователе, в том числе во время
        использования им Сервиса.
        <br />
        <br />
        1.6. Посещение и использование Сервиса означает безоговорочное согласие
        пользователя на обработку его персональных данных, указанных в статье 3
        настоящей Политики для целей, указанных в статье 4 настоящей Политики;
        <br />
        <br />
        1.7. В случае несогласия с условиями обработки персональных данных
        Оператором, пользователь должен прекратить использование Сервиса.
        <br />
        <br />
        1.8. Настоящая Политика применяется только к Сервису. Оператор не
        контролирует и не несет ответственности за сбор и обработку персональных
        данных третьими лицами, на сервисы которых пользователь может перейти по
        ссылкам, доступным на Сервисе.
        <br />
        <br />
        1.9. Оператор не осуществляет проверку достоверности персональных
        данных, предоставленных пользователем.
      </p>
      <span className={"spanOferta"}>2. ОСНОВНЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</span>
      <p>
        2.1. Сервис (Сервис) — совокупность размещенных в сети Интернет
        веб-страниц, объединенных единым адресным пространством домена
        https://matricadyshi.ru, а также интегрированные с ними приложения для
        мобильных устройств.
        <br />
        <br />
        2.2. Персональные данные – любая информация, относящаяся прямо или
        косвенно к определенному или определяемому физическому лицу (субъекту
        персональных данных).
        <br />
        <br />
        2.3. Обработка персональных данных – любое действие (операция) или
        совокупность действий (операций), совершаемых с использованием средств
        автоматизации или без использования таких средств с персональными
        данными, включая сбор, запись, систематизацию, накопление, хранение,
        уточнение (обновление, изменение), извлечение, использование, передачу
        (распространение, предоставление, доступ), обезличивание, блокирование,
        удаление, уничтожение персональных данных.
        <br />
        <br />
        2.4. Пользователь — лицо, имеющее доступ к Сервису посредством сети
        Интернет и использующее Сервис.
        <br />
        <br />
        2.5. Cookies — небольшой фрагмент данных, отправленный веб-сервером и
        хранимый на компьютере пользователя, который веб-Пользователь или
        веб-браузер пересылает веб-серверу в HTTP-запросе при попытке открыть
        страницу Сервиса.
      </p>
      <span className={"spanOferta"}>
        3. СОСТАВ ПОЛУЧАЕМЫХ И ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ
      </span>
      <p>
        3.1. Данные о пользователе, получаемые и обрабатываемые в рамках
        настоящей Политики поступают Оператору следующими способами:
        <br />
        <br />
        3.1.1. Предоставляются самим пользователем на Сервисе при заполнении
        форм ввода персональных и могут включать в себя следующую информацию:
        <br />
        <br />
        – фамилия, имя;
        <br />
        <br />
        – пол; <br />
        <br />
        – дата рождения,
        <br />
        <br />– номер телефона;
        <br />
        <br />
        – адрес электронной почты;
        <br />
        <br />
        3.1.2. Автоматически передаются Оператору в процессе посещения и
        использования Сервиса с помощью установленного на устройстве
        пользователя программного обеспечения (информация из файлов cookie) в
        том числе: сведения о местоположении; тип устройства пользователя и
        разрешение его экрана; тип, версия и язык операционной системы,
        установленной на устройстве пользователя; тип, версия и язык браузера
        (или иной программы, с помощью которой осуществляется доступ к Сервису);
        IP-адрес; адрес страницы, откуда пользователь перешел на Сервис (рефер),
        информация о том, какие страницы открывает и какие кнопки нажимает
        пользователь на Сервисе.
        <br />
        <br />
        На сайте https://matricadyshi.ru используется программа «Яндекс.Метрика»
        для отслеживания и веб-аналитики от компании «ЯНДЕКС» (Российская
        Федерация, 119021, Москва, ул. Льва Толстого, 16, ООО «ЯНДЕКС»). С ее
        помощью осуществляется сбор анонимных (без привязки к персональным
        данным пользователя) данных о посещениях сайта с целью лучшего понимания
        поведения. С дополнительными сведениями пользователь может ознакомится
        по адресу: http://legal.yandex.ru/metrica_termsofuse/.
        <br />
        <br />
        3.2. Оператор не выполняет обработку специальных категорий персональных
        данных, касающихся расовой, национальной принадлежности, политических
        взглядов, религиозных или философских убеждений.
      </p>
      <span className={"spanOferta"}>
        4. ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
      </span>
      <p>
        4.1. Сбор и обработка персональных данных пользователя, указанных в п.
        3.1.1 настоящей Политики осуществляется Оператором в целях:
        <br />
        <br />
        – идентификации пользователя на Сервисе и предоставления ему доступа к
        функциональным возможностям Сервиса;
        <br />
        <br />
        – связи с пользователем в случае необходимости, в том числе направления
        уведомлений, запросов и информации, связанных с использованием Сервиса,
        а также обработки запросов и заявок от пользователя.
        <br />
        <br />
        – предоставления пользователю новостной рассылки и иных сведений от
        имени Оператора, и его партнеров.
        <br />
        <br />
        4.2. Сбор и обработка персональных данных пользователя, указанных в п.
        3.1.2. осуществляется Оператором в целях создания статистики, которая
        помогает понять, как пользователи используют Сервис, что позволяет
        оптимизировать его структуру и содержание, повысить удобство
        использования Сервиса.
        <br />
        <br />
        Пользователь может в любой момент внести изменения в настройки файлов
        cookie и блокировать автоматическую передачу указанных файлов. Подробная
        информация о возможности и способах передачи файлов cookie доступна в
        настройках веб-браузера. Ограничения в использовании файлов cookie могут
        отразиться на некоторых функциях, доступных на веб-страницах сайта
        https://matricadyshi.ru.
        <br />
        <br />
        4.3. Не допускается обработка персональных данных, которые не отвечают
        целям обработки, указанным в пунктах 4.1. и 4.2. настоящей Политики.
      </p>
      <span className={"spanOferta"}>
        5. ПРЕДОСТАВЛЕНИЕ ДОСТУПА К ПЕРСОНАЛЬНЫМ ДАННЫМ
      </span>
      <p>
        5.1. Срок обработки персональных данных Пользователя не ограничен.
        Процедура обработки может проводиться любым предусмотренным
        законодательством РФ способом. В частности, с помощью информационных
        систем персональных данных, которые могут вестись автоматически либо без
        средств автоматизации.
        <br />
        <br />
        5.2. Обрабатываемые персональные данные уничтожаются либо обезличиваются
        Оператором по достижении целей обработки или в случае утраты
        необходимости в достижении этих целей, а также при отзыве Пользователем
        согласия на обработку персональных данных.
        <br />
        <br />
        5.3. Пользователь вправе в любой момент отозвать согласие на обработку
        Оператором персональных данных путём направления письменного уведомления
        на адрес электронной почты: info@matricadyshi.ru с пометкой «Отзыв
        согласия на обработку персональных данных». Отзыв пользователем согласия
        на обработку персональных данных влечёт за собой уничтожение записей,
        содержащих персональные данные, в системах обработки персональных данных
        Оператора, в том числе удаление аккаунта пользователя на Сервисе.
        <br />
        <br />
        5.4. Пользователь вправе требовать от Оператора уточнения его
        персональных данных, их блокирования или уничтожения в случае, если
        персональные данные являются неполными, устаревшими, неточными,
        незаконно полученными или не являются необходимыми для заявленной цели
        обработки, а также принимать иные предусмотренные законодательством
        Российской Федерации меры по защите своих прав.
        <br />
        <br />
        5.5. Право пользователя на изменение, удаление, блокирование
        персональной информации может быть ограничено требованиями положений
        законодательства Российской Федерации.
        <br />
        <br />
        5.6. В отношении персональных данных пользователя сохраняется их
        конфиденциальность, кроме случаев, установленных в п. 5.7. настоящей
        Политики.
        <br />
        <br />
        5.7. Оператор вправе осуществлять передачу персональных данных
        пользователя третьим лицам в следующих случаях:
        <br />
        <br />
        5.7.1. Передача предусмотрена российским законодательством в рамках
        установленной процедуры (по решению суда, запросу правоохранительных
        органов и т.д.);
        <br />
        <br />
        5.7.2. В целях обеспечения возможности защиты прав и законных интересов
        Оператора.
        <br />
        <br />
        5.8. При утрате или разглашении персональных данных Оператор информирует
        пользователя об утрате или разглашении персональных данных.
      </p>
      <span className={"spanOferta"}>
        6. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ
      </span>
      <p>
        6.1. Уровень защищенности персональных данных пользователей
        соответствует требованиям, установленным в{" "}
        <Link style={{color: 'white'}} to={"https://www.consultant.ru/document/cons_doc_LAW_137356/"}>
          Постановлении Правительства Российской Федерации от 1 ноября 2012 г. №
          1119 «Об утверждении требований к защите персональных данных при их
          обработке в информационных системах персональных данных»
        </Link>
        .
        <br />
        <br />
        6.2. Оператор принимает необходимые организационные и технические меры
        для защиты персональных данных пользователя от неправомерного или
        случайного доступа, уничтожения, изменения, блокирования, копирования,
        распространения, а также от иных неправомерных действий третьих лиц в
        соответствии с требованиями{" "}
        <Link style={{color: 'white'}} to={"https://www.consultant.ru/document/cons_doc_LAW_146520/"}>
          Приказа ФСТЭК России от 18.02.2013 № 21 «Об утверждении Состава и
          содержания организационных и технических мер по обеспечению
          безопасности персональных данных при их обработке в информационных
          системах персональных данных»
        </Link>
        .
        <br />
        <br />
        6.3. Сайт https://matricadyshi.ru имеет сертификат безопасности SSL, с
        помощью которого информация передается между пользователем и Оператором
        в закодированном виде, с целью предотвращения ее перехвата и искажения
        во время пересылки.
      </p>
      <span className={"spanOferta"}>7. ОТВЕТСТВЕННОСТЬ</span>
      <p>
        7.1. В случае неисполнения своих обязательств, Оператор несёт
        ответственность за убытки, понесённые пользователем в связи с
        неправомерным использованием персональных данных, в соответствии с
        законодательством Российской Федерации, за исключением случаев,
        предусмотренных 7.2. настоящей Политики.
        <br />
        <br />
        7.2. В случае утраты или разглашения персональных данных пользователя
        Оператор не несёт ответственность, если данная информация стала
        публичным достоянием до её утраты или разглашения, либо была разглашена
        самим пользователем или с согласия пользователя.
      </p>
      <span className={"spanOferta"}>8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</span>
      <p>
        8.1. Оператор вправе вносить изменения и дополнения в настоящую
        Политику. Новая редакция Политики действует с момента ее размещения на
        Сервисе https://matricadyshi.ru, если иное не предусмотрено в новой
        редакции Политики.
        <br />
        <br />
        8.2. К отношениям в области обработки и защиты персональных данных, не
        урегулированным в настоящей Политике, применяется действующее
        законодательство Российской Федерации.
        <br />
        <br />
        8.3. Все предложения или вопросы, связанные с обработкой и защитой
        персональных данных Оператором следует направлять по адресу электронной
        почты info@matricadyshi.ru.
      </p>
    </div>
  );
};
