import React from "react";
import "./HistoryMatrix.scss";
import Box from "@mui/material/Box";
import { TablePersonal } from "./TablePersonal";
import { TableCompatibility } from "./TableCompatibility";
// import tri from '../../../../triangle.png'

export const HistoryMatrix = () => {
  const [matrix, setMatrix] = React.useState("personal");

  return (
    <div className={"userProfile"}>
      <span className={"spanTarifActive"}>История поисков</span>
      <Box className={'boxHistory'} sx={{ maxWidth: 300}}>
        <span className={'spanHistoryMatrix'}>Тип матрицы</span>
        <select
          className={'selectMatrix'}
          value={matrix}
          onChange={(e) => setMatrix(e.target.value)}
        >
          <option value="personal">Персональные</option>
          <option value="children">Детские</option>
          <option value="compatibility">Совместимости</option>
        </select>
      </Box>
      <div>
        {matrix === "personal" ? (
          <TablePersonal matrix={"personal"} />
        ) : matrix === "children" ? (
          <TablePersonal matrix={"children"} />
        ) : (
          <TableCompatibility />
        )}
      </div>
    </div>
  );
};
