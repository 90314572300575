import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { HashLink as Anchor } from "react-router-hash-link";
import { useAppSelector } from "../../redux-store/store";
import visa from "../../visa-icon.png";
import mastercard from "../../mastercard-icon.png";
import mir from "../../mir-icon.png";
import pdfUrl from '../../doc.pdf'; // Укажите путь к вашему PDF
export const Footer = () => {
  const auth = useAppSelector((state) => state.registrationReducer.auth);



  return (
    <footer className={"footer"}>

      <div className={"divGlavFooter"}>
        <div className={"divFooter"}>
          <span className={"spanFooter"}>Основные разделы</span>
          <Anchor
            smooth
            to={"/#methodScroll"}
            className={"linkFooter"}
          >
            О методе
          </Anchor>
          <Link className={"linkFooter"} to={"/blog"}>
            Блог
          </Link>
          <Link className={"linkFooter"} to={"/learn"}>
            Обучение
          </Link>
          <Anchor
            smooth
            to={"/#tarif"}
            className={"linkFooter"}
          >
            Тарифы
          </Anchor>
          <Link className={"linkFooter"} to={auth ? "/profile/user" : "/welcome"}>
            Личный кабинет
          </Link>
        </div>
        <div className={"divFooter"}>
          <span className={"spanFooter"}>Нужно больше информации?</span>
          <Link className={"linkFooter"} to={"/konf"}>
            Политика конфиденциальности
          </Link>
          <Link className={"linkFooter"} to={"/oferta"}>
            Публичная оферта
          </Link>
          <Link className={"linkFooter"} to={"https://t.me/mariymani"}>
            Поддержка
          </Link>
        </div>
        <div className={"divFooter"}>
          <span className={"spanFooter"}>Документы</span>
          <a className={"linkFooter"} href={pdfUrl} target="_blank" rel="noopener noreferrer">
            Свидетельство
          </a>
        </div>
        <div className={"divFooter"}>
          <span className={"spanFooter"}>О владельце сайта</span>
          <span className={"linkFooter"}>ИП Приемыхова М.Э.</span>
          <span className={"linkFooter"}>ОГРНИП 323508100183320</span>
          <span className={"linkFooter"}>ИНН 501501654377</span>
          <div className={'cardPay'}>
            <img src={visa} alt="" />
            <img src={mastercard} alt="" />
            <img src={mir} alt="" />
          </div>
        </div>
      </div>
    </footer>
  );
};
