import React from "react";
import "./Konf.scss";
import {Link} from "react-router-dom";

export const Oferta = () => {
    return (
        <div className={"divOfera"}>
            <span className={"spanWelcome"}>Публичная оферта</span>
            <span className={"spanOferta"}>
        Публичная оферта об использовании сервиса https://matricadyshi.ru
      </span>
            <p>
                Перед использованием сервиса https://matricadyshi.ru пожалуйста,
                ознакомьтесь с условиями настоящего документа.
            </p>
            <p>
                Настоящий документ «Публичная оферта об использовании сервиса
                https://matricadyshi.ru представляет собой предложение Администрации
                сайта https://matricadyshi.ru заключить договор на изложенных ниже
                условиях.
            </p>
            <span className={"spanOferta"}>1. ОСНОВНЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</span>
            <p>
                1.1. Сервис https://matricadyshi.ru (Сервис) – совокупность размещенных
                в сети Интернет веб-страниц, объединенных единым адресным пространством
                домена https://matricadyshi.ru, а также интегрированные с ними
                приложения для мобильных устройств, предназначенные для ознакомления с
                информацией об Услугах и приобретения Услуг посредством сети Интернет.
                <br/>
                <br/>
                1.2. Администрация – осуществляющая деятельность по оказанию Услуг.
                Администрации принадлежат все соответствующие исключительные права на
                Сервис.
                <br/>
                <br/>
                1.3. Пользователь – физическое лицо, индивидуальный предприниматель или
                юридическое лицо, заключившие Договор с Администрацией в своем или чужом
                интересе условиях настоящей Оферты и, тем самым, получившие право
                использовать сервис и получать получить Услуги Администрации.
                <br/>
                <br/>
                1.4. Услуга – деятельность Администрации, связанная оказанием услуг по
                составлению индивидуальной информации о человеке на основании его имени
                и даты рождения.
                <br/>
                <br/>
                В целях настоящей оферты под услугами понимается следующее:
                <br/>
                <br/>
                Курсовое онлайн-обучение методу «Матрица Судьбы» посредством
                предоставления доступа к обучающему руководству, а именно: основные
                расчеты и расшифровке «Матрицы Судьбы». По окончанию обучения и сдачи
                итогового теста выдается сертификат консультанта.
                <br/>
                <br/>
                Предоставление доступа в чат консультантов:
                <br/>
                <br/>
                Предоставление доступа к сервису определения совместимости и полной
                расшифровки матрицы судьбы (в формате ограниченного либо неограниченного
                доступа в зависимости от выбранного тарифа).
                <br/>
                <br/>
                1.5. Тариф – определенный пакет услуг, предоставляемый Пользователю за
                установленную плату, на условиях ограниченного или неограниченного
                доступа. С детальным описанием каждого тарифа и его актуальной
                стоимостью можно ознакомиться по ссылке –
                https://matricadyshi.ru/#/#tarif.
                <br/>
                <br/>
                1.6. Заказ – оформленный надлежащим образом запрос Пользователя на
                оказание Услуг.
                <br/>
                <br/>
                1.7. Оферта – текст настоящего документа со всеми приложениями,
                изменениями и дополнениями к нему, размещенный в Сервисе и содержащий
                условия и порядок использования Сервиса и оказания Услуг.
                <br/>
                <br/>
                1.8. Договор – договор на использование Сервиса и оказание Услуг,
                который заключается и исполняется Администрацией и Пользователем в
                порядке и на условиях, предусмотренных настоящей Офертой.
                <br/>
                <br/>
                1.9. Правила – совокупность прав и обязанностей пользователей Сервиса.
            </p>
            <span className={"spanOferta"}>2. ОБЩИЕ ПОЛОЖЕНИЯ</span>
            <p>
                2.1. Договор, заключенный на условиях настоящей Оферты является
                юридически обязательным документом и регулирует отношения между
                Администрацией и Пользователем, возникающие при использовании Сервиса и
                приобретении Услуг.
                <br/>
                <br/>
                2.2. Приобретая Услуги посредством Сервиса, Пользователь соглашается с
                тем, что:
                <br/>
                <br/>
                а) он ознакомился с условиями настоящей Оферты в полном объеме.
                <br/>
                <br/>
                б) оплата Услуг означает, что он принимает все условия настоящей Оферты
                в полном объеме без каких-либо изъятий и ограничений с его стороны
                (акцепт). Договор, заключаемый путем акцепта настоящей Оферты, не
                требует двустороннего подписания и действителен в электронном виде.
                <br/>
                <br/>
                в) если Пользователь не согласен с условиями настоящей Оферты или не
                имеет права на заключение Договора в силу закона, ему следует отказаться
                от использования Сервиса.
                <br/>
                <br/>
                г) Оферта (в том числе любая из ее частей) может быть изменена
                Администрацией без какого-либо специального уведомления. Новая редакция
                Оферты вступает в силу с момента ее размещения в Сервисе, если иное не
                предусмотрено новой редакцией Оферты.
                <br/>
                <br/>
                д) Соглашаются с Правилами пользования Сервисом и обязуется не нарушать
                эти Правила.
                <br/>
                <br/>
                2.3. Отношения Сторон могут быть дополнительно урегулированы отдельными
                документами и соглашениями, регламентирующими использование
                соответствующих Сервисов. Применение таких дополнительных документов и
                соглашений не отменяет действие настоящей Оферты.
            </p>
            <span className={"spanOferta"}>3. ПРЕДМЕТ ДОГОВОРА</span>
            <p>
                3.1. Администрация обязуется предоставить Пользователю доступ к
                использованию Сервиса, а также оказать Услуги на основании оформленных
                Заказов, а Пользователь обязуется использовать Сервис в соответствии с
                Договором и оплатить Услуги на условиях настоящей Оферты в соответствии
                с утвержденными тарифами.
                <br/>
                <br/>
                3.1.1. В целях настоящей оферты применяются следующие тарифы:
                <br/>
                <br/>
                3.1.1.0. Тариф «Пробный» (включает в себя предоставление доступа к одной
                полной расшифровке «Матрице Судьбы», либо одной совместимости, либо
                одной детской матрице. При этом доступ будет открыт сразу после оплаты.
                <br/>
                <br/>
                3.1.1.1. Тариф «Раз и навсегда» (включает в себя предоставление доступа
                к полной расшифровке к «Матрицы Судьбы», определению совместимости,
                детской Матрицы судьбы без ограничения количества введенных дат. При
                этом доступ предоставляется бессрочно).
                <br/>
                <br/>
                3.1.1.2. Тариф «Матрица на месяц» (включает в себя предоставление
                доступа к полной расшифровке к «Матрицы Судьбы», определению
                совместимости без ограничения количества введенных дат. При этом доступ
                предоставляется сроком на 1 (один) месяц.
                <br/>
                <br/>
                3.1.1.3. Тариф «Видео курс+ раз и навсегда» (включает в себя
                предоставление доступа к видеокурсу по методу Матрицы судьбы обучающему
                руководству для самостоятельного онлайн-обучения расшифровкам «Матрицы
                Судьбы, а также доступ к полной расшифровке к «Матрицы Судьбы»,
                определению совместимости и детской Матрицы судьбы без ограничения
                количества введенных дат. При этом доступ предоставляется бессрочно).
                <br/>
                <br/>
                3.1.1.4. Тариф «Видеокурс» (включает в себя предоставление доступа к
                базовому видеокурсу по методу Матрицы судьбы, сертификат после сдачи
                итогового тестирования, доступ в чат поддержки по обучению. При этом
                доступ предоставляется бессрочно).
                <br/>
                <br/>
                3.1.1.5. Тариф «Урок по детской матрице судьбы» (включает в себя
                предоставление доступа к видеоуроку и обучающему руководству для
                самостоятельного онлайн-обучения, доступ в чат поддержки по обучению.
                При этом доступ предоставляется бессрочно).
                <br/>
                <br/>
                3.1.1.6. Тариф «Урок по матрице совместимости» (включает в себя
                предоставление доступа к видеоуроку и обучающему руководству для
                самостоятельного онлайн-обучения, доступ в чат поддержки по обучению.
                При этом доступ предоставляется бессрочно).
                <br/>
                <br/>
                3.1.1.7. Тариф «Матрица на год» (включает в себя предоставление доступа к полной расшифровке к «Матрицы
                Судьбы», определению совместимости без ограничения количества введенных дат. При этом доступ
                предоставляется сроком на 1 (один) календарный год.
                <br/>
                <br/>
                3.2. Наименование, цена, количество Услуг, а также прочие необходимые
                условия Договора определяются на основании сведений, предоставленных
                Пользователем при оформлении Заказа.
                <br/>
                <br/>
                3.3. Обязательным условием заключения Договора является безоговорочное
                принятие Пользователем условий Данной оферты, политики
                конфиденциальности, правил пользования Сервисом, а так же согласие на
                обработку персональной информации, согласно действующему
                Законодательству РФ.
            </p>
            <span className={"spanOferta"}>4. РЕГИСТРАЦИЯ</span>
            <p>
                4.1. Для того чтобы воспользоваться Услугами, Пользователь проходит
                процедуру регистрации, в результате которой для него создается
                персональный аккаунт на Сервисе.
                <br/>
                <br/>
                4.2. При регистрации Пользователь указывает следующие данные: фамилия,
                имя, пол, дата рождения, адрес электронной почты логин, пароль.
                Пользователь обязуется предоставить достоверную и полную информацию о
                себе.
                <br/>
                <br/>
                4.3. Учетные данные Пользователя, указанные им при регистрации,
                обрабатываются Администрацией для выполнения обязательств перед
                Пользователем. Пользователь выражает свое согласие Администрации на
                обработку учетных и персональных данных. При этом Администрация не несет
                ответственности перед любыми третьими лицами за точность и достоверность
                учетных данных Пользователя.
                <br/>
                <br/>
                4.4. Вход на Сервис осуществляется каждый раз путем прохождения
                процедуры авторизации — введения логина и пароля, в результате
                автоматической авторизации с использованием технологии cookies,
                получения данных от социальных сетей, привязанных к аккаунту и иными
                способами. При доступе к Сервису может применяться технология cookies в
                целях автоматической авторизации на Сервисе, а также для сбора
                статистических данных, в частности о посещаемости Сервиса. Пользователь
                вправе ограничить или запретить использование технологии cookies путем
                применения соответствующих настроек своего браузера или мобильного
                устройства.
                <br/>
                <br/>
                4.5. Любые действия, совершенные с использованием логина и пароля
                Пользователя, считаются совершенными соответствующим Пользователем.
                <br/>
                <br/>
                4.6. В случае несанкционированного доступа к логину и паролю и/или
                аккаунту, или распространения логина и пароля Пользователь обязан
                незамедлительно сообщить об этом Администрации.
            </p>
            <span className={"spanOferta"}>
        5. ПОРЯДОК ОФОРМЛЕНИЯ И ОКАЗАНИЯ УСЛУГ
      </span>
            <p>
                5.1. Сервис предназначен для ознакомления с информацией об Услугах и
                приобретения Услуг посредством сети Интернет. К отношениям между
                Пользователем и Администрацией в части оказания Услуг применяются
                положения главы 30 Гражданского кодекса Российской Федерации о
                возмездном оказании услуг, а также{" "}
                <Link style={{color: 'white'}} to={"https://www.consultant.ru/document/cons_doc_LAW_305/"}>
                    Закон Российской Федерации от 07.02.1992 г. «О защите прав
                    потребителей»{" "}
                </Link>
                и иные правовые акты, принятые в соответствии с ними.
                <br/>
                <br/>
                5.1.1. В целях исполнения условий, изложенных в настоящей оферте,
                последняя по своей правовой природе признается договором присоединения с
                исполнением по требованию согласно{" "}
                <Link
                  style={{color: 'white'}}
                    to={
                        "https://www.consultant.ru/document/cons_doc_LAW_5142/9d688d8d974b72f3a0f35b2eee9605da4a2bddd0/#:~:text=%D0%A0%D0%A4%20%D0%A1%D1%82%D0%B0%D1%82%D1%8C%D1%8F%20428.-,%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%20%D0%BF%D1%80%D0%B8%D1%81%D0%BE%D0%B5%D0%B4%D0%B8%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F,%D0%BA%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%BD%D0%BE%D0%BC%D1%83%20%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%D1%83%20%D0%B2%20%D1%86%D0%B5%D0%BB%D0%BE%D0%BC."
                    }
                >
                    ст. 428
                </Link>{" "}
                и{" "}
                <Link
                  style={{color: 'white'}}
                    to={
                        "https://www.consultant.ru/document/cons_doc_LAW_5142/b249909d7a07e016e688ee12687c85877017b515/"
                    }
                >
                    ст. 429.4
                </Link>{" "}
                ГК РФ, а именно:
                <br/>
                <br/>
                • Договором присоединения признается договор, условия которого
                определены одной из сторон в формулярах или иных стандартных формах и
                могли быть приняты другой стороной не иначе как путем присоединения к
                предложенному договору в целом.
                <br/>
                <br/>
                • Договором с исполнением по требованию (абонентским договором)
                признается договор, предусматривающий внесение одной из сторон
                (абонентом) определенных, в том числе периодических, платежей или иного
                предоставления за право требовать от другой стороны (исполнителя)
                предоставления предусмотренного договором исполнения в затребованных
                количестве или объеме либо на иных условиях, определяемых абонентом.
                Абонент обязан вносить платежи или предоставлять иное исполнение по
                абонентскому договору независимо от того, было ли затребовано им
                соответствующее исполнение от исполнителя, если иное не предусмотрено
                законом или договором.
                <br/>
                <br/>
                5.2. Пользователь самостоятельно осуществляет выбор Услуги, заполняет
                форму, представленную в Сервисе, и оплачивает Услугу.
                <br/>
                <br/>
                5.3. После оплаты Услуги Администрация осуществляет составление
                индивидуальной информации о человеке на основании предоставленного
                Пользователем имени и даты рождения и предоставляет указанную информацию
                Пользователю.
                <br/>
                <br/>
                5.4. Пользователь несет полную ответственность за предоставление
                неверных сведений, повлекшее за собой невозможность надлежащего
                исполнения Администрацией своих обязательств перед ним.
            </p>
            <span className={"spanOferta"}>6. ОПЛАТА УСЛУГ</span>
            <p>
                6.1. Цены за Услуги, реализуемые Администрацией посредством Сервиса,
                указываются в национальной валюте – рублях Российской Федерации.
                <br/>
                <br/>
                6.2. Администрация вправе в любое время в одностороннем порядке изменять
                цены на Услуги. При этом, цена на Услугу, которая уже была оплачена,
                изменению не подлежит,
                <br/>
                <br/>
                6.3. Доступные способы оплаты Услуги предлагаются Пользователю после
                заполнения им формы заказа.
                <br/>
                <br/>
                6.4. При оплате Услуг с помощью банковских карт Пользователю следует
                учитывать следующее:
                <br/>
                <br/>• в соответствии с{" "}
                <Link style={{color: 'white'}} to={"https://www.consultant.ru/document/cons_doc_LAW_52513/"}>
                    положением ЦБ РФ «Об эмиссии банковских карт и об операциях,
                    совершаемых с использованием платежных карт» от 24.12.2004 года №
                    266-П
                </Link>{" "}
                п операции по банковским картам совершаются держателем карты либо
                уполномоченным им лицом.
                <br/>
                <br/>
                • авторизация операций по банковским картам осуществляется банком. Если
                у банка есть основания полагать, что операция носит мошеннический
                характер, то банк вправе отказать в осуществлении данной операции.
                <br/>
                <br/>
                6.5. Администрация вправе предоставлять скидки, бонусы и иные льготные
                условия приобретения Услуг всем или отдельными Пользователями.
                <br/>
                <br/>
                6.6. Администрация вправе ограничить доступные способы оплаты Услуг
                Пользователем.
            </p>
            <span className={"spanOferta"}>7. ОТЗЫВЫ</span>
            <p>
                7.1. Сервис является информационной площадкой, позволяющей Пользователям
                оставлять свои отзывы об Услугах.
                <br/>
                <br/>
                7.2. Оставлять отзывы может любой Пользователь. Отзывы могут быть
                исправлены в части грамматики и пунктуации Администрацией, при этом
                Пользователь несет ответственность за достоверность сведений,
                содержащихся в оставленных им отзывах. Администрация вправе
                самостоятельно и без уведомления Пользователя модерировать отзывы, в том
                числе:
                <br/>
                <br/>
                7.2.1. не публиковать отзывы, содержание которых не относится к тематике
                Сервиса;
                <br/>
                <br/>
                7.2.2. не публиковать отзывы, которые не содержат полезной информации
                для других пользователей Сервиса;
                <br/>
                <br/>
                7.2.3. не публиковать отзывы, которые содержат ненормативную лексику,
                высказывания оскорбительного характера;
                <br/>
                <br/>
                7.2.4. не публиковать отзывы, содержащие ссылки на другие веб-Сервисы;
                <br/>
                <br/>
                7.2.5. не публиковать отзывы, информация в которых явно недостоверна или
                вызывает сомнения в ее достоверности;
                <br/>
                <br/>
                7.2.6. удалить опубликованный отзыв в любое время;
                <br/>
                <br/>
                7.2.7. самостоятельно определять срок, в течение которого отзывы
                считаются актуальными и на который они публикуются.
                <br/>
                <br/>
                7.3. Администрация не принимает на себя обязательств сообщать
                Пользователю о причинах отклонения публикации и/или удаления ранее
                опубликованных отзывов.
                <br/>
                <br/>
                7.4. По просьбам других пользователей отзывы не удаляются. Отзыв может
                быть удален только в случае, если будут обнаружены признаки пиара или
                троллинга, а также по просьбе автора отзыва.
            </p>
            <span className={"spanOferta"}>8. ПРАВИЛА ПОЛЬЗОВАНИЯ СЕРВИСОМ</span>
            <p>
                8.1. Пользователь вправе:
                <br/>
                <br/>
                • Знакомится с материалами, представленными на Сервисе и оформлять
                Заказы на Услуги;
                <br/>
                <br/>
                • Отказаться от исполнения Договора при отказе Администрации оказать
                Услугу;
                <br/>
                <br/>
                • Использовать Сервис не запрещенными Договором и действующим
                законодательством РФ способами;
                <br/>
                <br/>
                • Обратиться к Администрации для выяснения ситуации и принятия
                необходимых мер в случае возникновения в работе Сервиса проблем
                технического характера, а также в случае получения Пользователем
                сообщений, являющихся несанкционированной рекламной рассылкой, либо
                содержащих угрозы или файлы с подозрением на вирус;
                <br/>
                <br/>
                • Обращаться к Администрации по вопросам функционирования Сервиса и его
                инструментов;
                <br/>
                <br/>
                • Осуществлять иные, не запрещенные законодательством Российской
                Федерации действия, связанные с использованием Сервиса.
                <br/>
                <br/>
                8.2. Пользователь обязуется:
                <br/>
                <br/>
                • Ознакомиться с содержанием настоящей Оферты, а также с
                характеристиками Услуг;
                <br/>
                <br/>
                • Предоставлять достоверную информацию для получения надлежащих
                результатов оказания Услуг;
                <br/>
                <br/>
                • Оплатить стоимость Услуг;
                <br/>
                <br/>
                • Не использовать Сервис для публикации, распространения, хранения,
                передачи в любой форме информации и материалов, которые носят
                непристойный, оскорбительный, вульгарный, вредоносный, угрожающий,
                клеветнический, ложный или порнографический характер;
                <br/>
                <br/>
                • В любых формах и выражениях оскорблять честь и достоинство, права и
                законные интересы третьих лиц, способствуют разжиганию религиозной,
                расовой, этнической или межнациональной розни, содержат элементы
                насилия, призывают к нарушению действующего законодательства и
                противоправным действиям и т.п.;
                <br/>
                <br/>
                • Не нарушать права на результаты интеллектуальной деятельности и на
                средства индивидуализации (в том числе авторские, смежные, патентные и
                т.д.) этого Сервиса и третьих лиц;
                <br/>
                <br/>
                • Не нарушать права несовершеннолетних лиц;
                <br/>
                <br/>
                • Не способствовать возникновению интереса к распространению наркотиков,
                оружия и боеприпасов, любой форме террористической, противоправной и
                нацистской деятельности;
                <br/>
                <br/>
                • Не разглашать конфиденциальную информацию, которая стала доступна
                Пользователю в результате использования Сервиса (в том числе информацию,
                составляющую государственную или коммерческую тайну, персональные данные
                третьих лиц и т.п.);
                <br/>
                <br/>
                • Не подвергать пользователей Сервиса какой-либо программной и иной
                технической опасности (заражение компьютеров пользователей вредоносным
                программным обеспечением, несогласованную рекламу, флуд, спам и иную
                информацию, которая может навредить корректной работе Сервиса);
                <br/>
                <br/>
                • Постоянно следить за внесенными в настоящее Соглашение изменениями;
                <br/>
                <br/>
                • Соблюдать действующее законодательство РФ, не нарушать права третьих
                лиц;
                <br/>
                <br/>
                • Не продавать или иным образом передавать свои данные к учетной записи
                Сервиса другим лицам;
                <br/>
                <br/>• Указывать авторство при размещении в сети Интернет информации,
                которая содержится на этом сайте (ссылка на сайт, публикацию).
            </p>
            <span className={"spanOferta"}>9. ПРАВА И ОБЯЗАННОСТИ АДМИНИСТРАЦИИ</span>
            <p>
                9.1. Администрация вправе:
                <br/>
                <br/>
                • Заблокировать (ограничить) доступ пользователю за нарушение Правил
                данного Соглашения;
                <br/>
                <br/>
                • Уведомлять пользователей про дату и время проведения технических работ
                Сервиса;
                <br/>
                <br/>
                • Размещать рекламу третьих лиц;
                <br/>
                <br/>
                • Запросить у пользователя предоставить корректные данные;
                <br/>
                <br/>
                • Изменять оформление Сервиса, его содержание, изменять или дополнять
                используемые скрипты, программное обеспечение, контент и другие объекты,
                используемые или хранящиеся на Сервисе, любые серверные приложения;
                <br/>
                <br/>
                • С согласия Пользователя осуществлять рассылку ему сообщений о введении
                в действие новых, либо отмене старых сервисов;
                <br/>
                <br/>
                • Показывать Пользователю рекламные материалы на Сервисе;
                <br/>
                <br/>
                • Удалять без каких-либо причин и без предупреждения любой контент на
                Сервисе;
                <br/>
                <br/>
                • Полностью или частично передавать свои права и обязанности по
                настоящему Соглашению третьим лицам в одностороннем порядке;
                <br/>
                <br/>
                • Блокировать пользователей, которые купили или иным другим путем
                получили доступ к аккаунту пользователя;
                <br/>
                <br/>
                • Осуществлять иные принадлежащие ему права.
                <br/>
                <br/>
                9.2. Администрация обязуется:
                <br/>
                <br/>
                • Предоставить право использовать Сервис круглосуточно 7 дней в неделю,
                включая выходные и праздничные дни, за исключением перерывов, связанных
                с проведением технических работ на Сервисе;
                <br/>
                <br/>
                • Приложить все разумные усилия по обеспечению стабильной работы
                Сервиса, постепенному его совершенствованию, исправлению ошибок в работе
                Сервиса, однако, Сервис предоставляется в пользование по принципу «как
                есть». Принцип «как есть» означает, что Администрация не может
                гарантировать
                <br/>
                <br/>
                • отсутствие ошибок в работе Сервиса;
                <br/>
                <br/>
                • не несет ответственность за бесперебойную работу Сервиса и его
                совместимость с программным обеспечением и техническими средствами
                Пользователя и иных лиц;
                <br/>
                <br/>
                • не несет ответственности за потерю данных или за причинение любых
                убытков, которые возникли или могут возникнуть в связи с использованием
                Сервиса;
                <br/>
                <br/>
                • не несет ответственности за неисполнение либо ненадлежащее исполнение
                своих обязательств вследствие сбоев в телекоммуникационных и
                энергетических сетях, действий вредоносных программ, а также
                недобросовестных действий третьих лиц, направленных на
                несанкционированный доступ и(или) выведение из строя программного и(или)
                аппаратного комплекса Сервиса;
                <br/>
                <br/>
                • Обеспечить конфиденциальность и сохранность данных, полученных от
                Пользователя кроме случаев, когда такое разглашение произошло по не
                зависящим от Администрации причинам, а также за исключением случаев,
                предусмотренных действующим законодательством Российской Федерации;
                <br/>
                <br/>
                • Осуществлять техническую поддержку Пользователя по вопросам
                функционирования Сервиса, оказания Услуг.
                <br/>
                <br/>
                9.3. Администрация осуществляет текущее управление Сервисом, определяет
                его структуру, внешний вид, разрешает или ограничивает доступ
                пользователей к Сервису, осуществляет иные принадлежащие ему права.
                <br/>
                <br/>
                9.4. В случае, если Пользователь нарушил какой-либо из пунктов настоящих
                Правил и Соглашения, Администрация оставляет за собой право прекратить
                доступ (заблокировать) Пользователя к Сервису путем блокирования доступа
                IP-адреса, с которого было совершено наибольшее количество посещений
                данным Пользователем и передать информацию и материалы, подтверждающие
                незаконные действия Пользователя, для принятия мер в правоохранительные
                и иные другие контрольные и надзорные органы.
                <br/>
                <br/>
                9.5. Пользователь признает и соглашается, что IP-адрес персонального ЭВМ
                фиксируется техническими средствами Администрации, и в случае совершения
                незаконных действий, в том числе действий, нарушающих права третьих лиц,
                ответственным за указанные незаконные действия признается владелец
                персонального ЭВМ, определяемого техническими средствами Администрации
                по принадлежности IP-адреса.
            </p>
            <span className={"spanOferta"}>
        10. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ И АВТОРСКИЕ ПРАВА НА РАЗМЕЩЕННЫЕ
        МАТЕРИАЛЫ
      </span>
            <p>
                10.1. Администрация сайта является единственным собственником всех
                интеллектуальных прав, размещенных на Сайте https://matricadyshi.ru/
                <br/>
                <br/>
                10.2. Администрация сайта вправе привлекать иных авторов для публикации
                их материалов на Сайте https://matricadyshi.ru/ с указанием их
                Авторства.
                <br/>
                <br/>
                10.3. Администрация, пользователи и иные Авторы согласны с тем, что
                авторское право охраняется с момента его создания и (или) его
                публикации. Администрация, пользователи и иные Авторы согласны с тем,
                что все нематериальные права принадлежат автору текста, а все
                имущественные права принадлежат Администрации Сайта.
                <br/>
                <br/>
                10.4. Все материалы, опубликованные на сайте https://matricadyshi.ru/
                охраняются авторским правом. Любое несанкционированное копирование и
                (или) публикация без указания авторства и ссылки на источник защищается
                действующим международным и Российским правом ®.
                <br/>
                <br/>
                10.5. Пользователи, в пункте 8.3 Соглашения, обязуются не нарушать
                интеллектуальную собственность Администрации Сайта и так же уведомлены,
                что на основании действующего Законодательства Российской Федерации
                предусмотрена ответственность за нарушение прав на интеллектуальную
                собственность. Согласно пп. «д» п. 2.2. Соглашения пользователь
                считается уведомленным про ответственность за нарушения прав на
                интеллектуальную собственность Администрации Сайта
                https://matricadyshi.ru/
            </p>
            <span className={"spanOferta"}>11. ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ</span>
            <p>
                11.1. Администрация не несет ответственности за предоставление
                результата оказания Услуг, если Пользователь указал недостоверную
                информацию.
                <br/>
                <br/>
                11.2. Администрация не гарантирует исполнения каких-либо прогнозов и
                предсказаний, данных Пользователю, равно как и не гарантируют достижения
                какого-либо объективного или субъективного эффекта и не несет
                ответственности за последствия и/или несоответствие ожиданий
                Пользователя от пользования Сервисом.
                <br/>
                <br/>
                11.3. Сервис предоставляется по принципу «как есть», в связи с чем
                Пользователю не представляются какие-либо гарантии, указанные в пункте
                9.2 Соглашения.
                <br/>
                <br/>
                11.4. Администрация прилагает все усилия к тому, чтобы размещенная на
                Сервисе информация была точной, однако, Администрация не даёт никакой
                гарантии в отношении точности такой информации и не несёт за это
                ответственности. Использование каких-либо материалов и данных из состава
                материалов Сервиса относится исключительно на риск Пользователя,
                Администрация не будет нести ответственности за какую-либо потерю
                прибыли, убытки или иной ущерб, произошедший в результате использования
                материалов Сервиса.
                <br/>
                <br/>
                11.5. Все рекламные материалы, размещаемые на Сервисе, предоставлены
                третьими лицами. Администрация не несет ответственности за достоверность
                рекламных материалов третьих лиц, доступность их интернет-сайтов и их
                содержание, а также за любые последствия, связанные с использованием
                информации и/или рекламы, а также интернет-сайтов третьих лиц.
                <br/>
                <br/>
                11.6. Поскольку Сервис находится на стадии постоянного дополнения и
                обновления новых функциональных возможностей, форма и характер
                предоставляемых услуг могут время от времени меняться без
                предварительного уведомления Пользователя. Администрация вправе по
                собственному усмотрению прекратить (временно или окончательно) показ
                материалов Сервиса и /или предоставление Услуг всем Пользователям вообще
                или отдельному Пользователю в частности без предварительного
                уведомления.
                <br/>
                <br/>
                11.7. Пользователь несет ответственность за любое нарушение
                обязательств, установленных настоящим Договором и действующим
                законодательством, а также за все последствия таких нарушений (включая
                любые убытки, которые может понести Администрация и иные третьи лица).
                <br/>
                <br/>
                11.8. Администрация оставляет за собой право преследования нарушителей
                исключительных прав на объекты интеллектуальной собственности в
                соответствии с гражданским, административным и уголовным
                законодательством по своему усмотрению.
                <br/>
                <br/>
                11.9. Администрация, предоставляя Пользователю техническую возможность
                использования Сервиса, не контролирует действия Пользователя, не
                осуществляет автоматическую цензуру информации в открытых разделах
                Сервиса, не несет ответственности за действия или бездействие
                Пользователя, поскольку в информационной системе Сервиса и его
                программном обеспечении отсутствуют технические решения, позволяющие
                осуществлять автоматическую цензуру и контроль действий Пользователей.
                <br/>
                <br/>
                11.10. Администрация не несет ответственности за возможные сбои и
                перерывы в работе Сервиса и вызванные ими потерю информации.
                Администрация не несет ответственности за любой ущерб компьютеру
                Пользователя, мобильным устройствам, любому другому оборудованию или
                программному обеспечению, вызванный или связанный с использованием
                Сервиса или сайтов (сервисов), доступных по гиперссылкам, размещенным на
                Сервисе.
                <br/>
                <br/>
                11.11. Администрация Сайта не несет ответственности за любой ущерб,
                включая упущенную выгоду, или вред, вызванные в связи с использованием
                Сервиса, контента или иных материалов, к которым Пользователь или иные
                лица получили доступ при помощи Сервиса, даже если Администрация Сайта
                предупреждала или указывала на возможность причинения такого ущерба или
                вреда.
                <br/>
                <br/>
                11.12. Администрация не несет ответственности за любой ущерб, который
                может быть причинен Пользователю, включая потерю данных, блокировку
                контента или прекращение функционирования Сервиса.
                <br/>
                <br/>
                11.13. Пользователь несет персональную ответственность за любую
                информацию, которую он загружает на Сервис или иным образом доводит до
                всеобщего сведения на Сервисе или с его помощью. Пользователь обязуется
                самостоятельно разрешать претензии третьих лиц, связанные с
                неправомерным размещением информации.
                <br/>
                <br/>
                11.14. Гиперссылки на любой сайт, продукт, услугу, любую информацию
                коммерческого или некоммерческого характера, размещенные на Сервисе, не
                являются одобрением или рекомендацией данных продуктов (услуг) со
                стороны Администрации. Администрация не несет ответственности за ущерб,
                причиненный Пользователю в результате перехода по таким гиперссылкам.
                <br/>
                <br/>
                11.15. Переход к сайтам (сервисам) третьих лиц, установка программ и
                потребление услуг третьих лиц осуществляется Пользователем на свой риск.
                К взаимоотношениям Пользователя и третьих лиц положения Договора не
                применяются.
            </p>
            <span id={"personalData"} className={"spanOferta"}>
        12. СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
      </span>
            <p>
                12.1. Посещая и используя Сервис (сайт https://matricadyshi.ru) я даю
                свое согласие Администрации сайта https://matricadyshi.ru (далее –
                Оператор) на обработку следующих моих данных:
                <br/>
                <br/>
                Фамилия, имя, пол, дата рождения, адрес электронной почты (e-mail),
                номер мобильного телефона, информация из файлов cookie, в том числе:
                сведения о местоположении; тип моего устройства и разрешение его экрана;
                тип, версия и язык операционной системы, установленной на моем
                устройстве; тип, версия и язык браузера (или иной программы, с помощью
                которой осуществляется доступ к сайту); IP-адрес; адрес страницы, откуда
                я перешел на сайт (рефер), информация о том, какие страницы я открываю и
                какие кнопки нажимаю на сайте.
                <br/>
                <br/>
                12.2. Согласие дается мною для обработки моих данных с использованием
                средств автоматизации и без использования средств автоматизации в целях:
                <br/>
                <br/>
                12.2.1. моей идентификации на сайте или в мобильном приложении и
                предоставления мне доступа к их функциональным возможностям;
                <br/>
                <br/>
                12.2.2. направления мне уведомлений, запросов и информации, связанных с
                использованием Сервиса.
                <br/>
                <br/>
                12.2.3. обработки моих запросов и заявок в адрес Оператора;
                <br/>
                <br/>
                12.2.4. предоставления мне новостной рассылки и иных сведений от имени
                Оператора, и его партнеров.
                <br/>
                <br/>
                12.2.5. создания статистики, которая помогает Оператору оптимизировать
                структуру и содержание Сервиса, повысить удобство его использования.
                <br/>
                <br/>
                12.3. Согласие на обработку персональных данных, данное мной действует в
                течение неопределенного срока, до его отзыва мною. Проинформирован о
                том, что вправе в любой момент отозвать согласие на обработку Оператором
                персональных данных путём направления уведомления на адрес электронной
                почты: info@matricadyshi.ru с пометкой «отзыв согласия на обработку
                персональных данных». С политикой в отношении обработки и защиты
                персональных данных пользователей сервиса https://matricadyshi.ru
                ознакомлен и согласен.
            </p>
            <span className={"spanOferta"}>13. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</span>
            <p>
                13.1. Все разногласия, возникающие между Сторонами, подлежат
                урегулированию путем переговоров. Досудебный порядок разрешения спора,
                вытекающего из отношений, регулируемых Договором, считается
                обязательным. Срок для досудебного урегулирования претензий составляет
                30 (тридцать) дней с момента получения соответствующей претензии. При
                недостижении согласия по поводу урегулирования спора, такой спор
                подлежит рассмотрению и разрешению в суде по месту нахождения
                Администрации.
                <br/>
                <br/>
                13.2. К отношениям между Пользователем и Администрацией применяется
                право Российской Федерации.
                <br/>
                <br/>
                13.3. Признание судом какого-либо положения Оферты недействительным и не
                подлежащим применению не влечет за собой недействительности иных
                положений Оферты.
            </p>
        </div>
    );
};
