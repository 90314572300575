import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppSelector } from "../redux-store/store";
import { PropsTypeForecastTheYearChildren } from "../type/personalMatrix-type";
import { ForecastForTheYearChildren } from "./ForecastForTheYearChildren";
import { filterCalcYear } from "../utils/calc";
import { HashLink as Anchor } from "react-router-hash-link";
import LockIcon from "@mui/icons-material/Lock";
import { v4 } from "uuid";

export function SimpleAccordionChildren(
  props: PropsTypeForecastTheYearChildren
) {
  const personalMatrixChildren = useAppSelector(
    (state) => state.personalMatrixChildrenReducer
  );
  const yearHandler = filterCalcYear(
    personalMatrixChildren.data.isYear,
    props.gender ? props.gender : "W"
  );
  const accordion = (
    txt: any,
    arr: {
      paragraph: string;
      value: { text: string; value: number, }[],
      colorBall?: string;
      nums?: { nomer: number, clas: string }[];
    }[],
    lock: boolean,
    staticTxt: string
  ) => {
    return (
      <Accordion style={{
        backgroundColor: "rgba(240, 213, 253, 1)",
        marginBottom: 5, borderRadius: 10,
        color: "rgba(46, 32, 81, 1)"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {!lock ? (
              <b style={{
                display: "flex",
                fontSize: 24,
                fontFamily: "Golos",
                color: "rgba(135, 102, 173, 1)"
              }}>{txt}</b>
            ) : (
              <b style={{
                display: "flex",
                fontSize: 24,
                alignItems: "center",
                fontFamily: "Golos",
                color: "rgba(135, 102, 173, 1)"
              }}>
                {arr[0].value?.length === undefined ? (
                  <LockIcon style={{ color: "rgba(135, 102, 173, 1)", marginRight: "15px" }} />
                ) : (
                  ""
                )}{" "}
                {txt}
              </b>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {lock ? (
              arr[0].value?.length === undefined ? (
                <React.Fragment>
                  <p style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 24,
                    fontFamily: "Golos",
                    marginBottom: 20,
                    color: "rgba(135, 102, 173, 1)"
                  }}>
                    Будет доступно после оформления одного из тарифов
                  </p>
                  <Anchor smooth to={"/#tarif"} className={"batonStandart"}>
                    ТАРИФЫ
                  </Anchor>
                </React.Fragment>
              ) : (
                <Typography>
                  <p>{staticTxt.split("\n").map((paragraph) => (
                    <React.Fragment key={v4()}>
                      <b style={{ fontSize: 24, color: "rgba(135, 102, 173, 1)" }}>{paragraph}</b>
                      <br key={v4()} />
                    </React.Fragment>
                  ))}</p>
                  <br />
                  {arr.map((el) => {
                    return (
                      <React.Fragment key={v4()}>
                        <b style={{ fontSize: 24, color: "rgba(135, 102, 173, 1)" }}>{el.paragraph}</b>
                        <br />
                        {el.value.map((e) => (
                          <React.Fragment key={v4()}>
                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                              {el.colorBall ? <div
                                style={{ fontSize: 16 }}
                                className={el.colorBall}
                              >
                                {e.value}
                              </div> : <div style={{ display: "flex", flexDirection: "column" }}>
                                {el.nums?.map(num => (
                                  num.nomer === e.value ? <div
                                    style={{ fontSize: 16 }}
                                    className={num.clas}
                                  >
                                    {e.value}
                                  </div> : undefined
                                ))}
                              </div>}
                              <div style={{ width: "85%" }}>
                                {e.text.split("\n").map((paragraph) => (
                                  <React.Fragment key={v4()}>
                                    {paragraph}
                                    <br key={v4()} />
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
                            <br />
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    );
                  })}
                </Typography>
              )
            ) :
            (
              <Typography>
                <p>{staticTxt.split("\n").map((paragraph) => (
                  <React.Fragment key={v4()}>
                    <b style={{ fontSize: 24, color: "rgba(135, 102, 173, 1)" }}>{paragraph}</b>
                    <br key={v4()} />
                  </React.Fragment>
                ))}</p>
                <br />
                {arr.map((el) => {
                  return (
                    <React.Fragment key={v4()}>
                      <b style={{ fontSize: 24, color: "rgba(135, 102, 173, 1)" }}>{el.paragraph}</b>
                      <br />
                      {el.value.map((e) => (
                        <React.Fragment key={v4()}>
                          <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            {el.colorBall ? <div
                              style={{ fontSize: 16 }}
                              className={el.colorBall}
                            >
                              {e.value}
                            </div> : <div style={{ display: "flex", flexDirection: "column" }}>
                              {el.nums?.map(num => (
                                num.nomer === e.value ? <div
                                  style={{ fontSize: 16 }}
                                  className={num.clas}
                                >
                                  {e.value}
                                </div> : undefined
                              ))}
                            </div>}
                            <div style={{ width: "85%" }}>
                              {e.text.split("\n").map((paragraph) => (
                                <React.Fragment key={v4()}>
                                  {paragraph}
                                  <br key={v4()} />
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                          <br />
                        </React.Fragment>
                      ))}

                    </React.Fragment>
                  );
                })}
              </Typography>
            )}
        </AccordionDetails>
      </Accordion>
    );
  };
  return (
    <div
      className={"simpAccordion"}
      style={{ width: "100%", margin: "0 auto" }}
    >
      {accordion(
        "Личные качества",
        [
          {
            paragraph: "Характеристика качеств",
            value:
            personalMatrixChildren.data.isPersonalQualitiesChildren
              ?.isCharacteristicsOfQualities,
            colorBall: "-purple boll"
          },
          {
            paragraph: "Рекомендации для родителей",
            value:
            personalMatrixChildren.data.isPersonalQualitiesChildren
              .isRecommendationsForParents,
            colorBall: "-purple boll"
          },
          {
            paragraph: "Ребенок в общении",
            value:
            personalMatrixChildren.data.isPersonalQualitiesChildren
              .isChildInCommunication,
            colorBall: "-yellow boll"
          }
        ],
        false,
        "Описание личных качеств ребенка и его поведения в социуме позволяет нам получить представление о том, как его видят окружающие и как он проявляет себя в обществе. Здесь также представлены рекомендации для родителей о том, как избегать ошибок в воспитании и как помочь ребенку проявить себя."
      )}
      {accordion(
        "Таланты ребенка",
        [
          {
            paragraph: "Талант от рождения",
            value: personalMatrixChildren.data.isChildTalents?.isBirthTalents,
            nums: [{ nomer: props.B!, clas: "-purple boll" }, {
              nomer: props.B1!,
              clas: "-blue boll"
            }, { nomer: props.B2!, clas: "-cyan boll" }]
          },
          {
            paragraph: "Таланты по мужской линии",
            value:
            personalMatrixChildren.data.isChildTalents
              ?.isTalentsInTheMaleLine,
            colorBall: "-white boll"
          },
          {
            paragraph: "Таланты по женской линии",
            value:
            personalMatrixChildren.data.isChildTalents
              ?.isTalentsInTheFemaleLine,
            colorBall: "-white boll"
          },
          {
            paragraph: "Направления увлечений, хобби и кружков по интересам",
            value:
            personalMatrixChildren.data.isChildTalents
              ?.isDirectionsOfHobbiesAndHobbyGroups,
            nums: [{ nomer: props.B!, clas: "-purple boll" }, {
              nomer: props.B1!,
              clas: "-blue boll"
            }, { nomer: props.B2!, clas: "-cyan boll" }]
          }
        ],
        true,
        "Ребенок обладает различными способностями, которые могут быть физическими, познавательными, эмоциональными или творческими. Важно, чтобы родители помогали ребенку раскрыть свой заложенный потенциал и развивали его способности об этом узнаете в этом разделе"
      )}
      {accordion(
        "Отношения с родителями",
        [
          {
            paragraph: "Что нужно учитывать в вопросах воспитания ребенка",
            value:
            personalMatrixChildren.data.isRelationshipWithParents
              ?.isWhatToConsiderWhenRaisingAChild,
            nums: [{ nomer: props.A!, clas: "-purple boll" }, {
              nomer: props.A1!,
              clas: "-blue boll"
            }, { nomer: props.A2!, clas: "-cyan boll" }]
          },
          {
            paragraph: "Уроки по роду мужской линии",
            value:
            personalMatrixChildren.data.isRelationshipWithParents
              ?.isLessonsOnTheGenderOfTheMaleLine,
            colorBall: "-white boll"
          },
          {
            paragraph: "Уроки по роду женской линии",
            value:
            personalMatrixChildren.data.isRelationshipWithParents
              ?.isLessonsOnTheGenderOfTheFemaleLine,
            colorBall: "-white boll"
          }
        ],
        true,
        "Семья играет огромную роль в формировании характера и поведения ребенка. Опыт, полученный в отношениях с родителями, является фундаментом его личности и определяет его отношение к себе и взаимодействию с окружающим миром. В разделе вы ознакомитесь каких ошибок в воспитании следует избегать"
      )}
      {accordion(
        "Самореализация ребенка",
        [
          {
            paragraph: "Направление деятельности, варианты будущих профессий",
            value:
            personalMatrixChildren.data.isSelfRrealizationOfTheChild
              ?.isDirectionOfActivityOptionsForFutureProfessions,
            colorBall: "-white boll"
          },
          {
            paragraph: "Для достижения успеха важно",
            value:
            personalMatrixChildren.data.isSelfRrealizationOfTheChild
              ?.isForSuccessItIsImportant,
            nums: [{ nomer: props.C!, clas: "-red boll" }, {
              nomer: props.C1!,
              clas: "-white boll"
            }, { nomer: props.C2!, clas: "-orange boll" }, { nomer: props.G4!, clas: "-white boll" }]
          }
        ],
        true,
        "Каждый родитель стремится к благополучию и успешной самореализации своего ребенка. Для этого важно развивать определенные навыки и качества, которые помогут ребенку успешно адаптироваться и достигать своих целей в жизни об этом подробнее в этом разделе"
      )}
      {accordion(
        "Предназначение ребенка",
        [
          {
            paragraph: "Первое, личное предназначение",
            value:
            personalMatrixChildren.data.isPurposeOfTheChild
              ?.isFirstPersonalPurpose,
            colorBall: "borderMatrix boll"
          },
          {
            paragraph: "Второе, социальное предназначение",
            value:
            personalMatrixChildren.data.isPurposeOfTheChild
              ?.isSecondSocialPurpose,
            colorBall: "borderMatrix boll"
          }
        ],
        true,
        ""
      )}
      {accordion(
        "Страхи и блоки ребенка",
        [
          {
            paragraph: "Подсознательные страхи и блоки",
            value: personalMatrixChildren.data?.isSubconsciousScript,
            nums: [{ nomer: props.C!, clas: "-red boll" }, {
              nomer: props.D!,
              clas: "-red boll"
            }, { nomer: props.D1!, clas: "-white boll" }, { nomer: props.D2!, clas: "-orange boll" }]
          }
        ],
        true,
        "В разделе о кармических уроках ребенка описываются возможные страхи, комплексы, блоки и установки, которые могут оказывать негативное влияние на его жизнь. Эти энергии могут быть неосознанными для ребенка, но они могут тормозить его развитие и приводить к негативным ситуациям.\n" +
        "\n" +
        "Кармические уроки могут быть связаны с прошлыми жизнями и опытом, который ребенок несет с собой. Они могут проявляться в виде страхов, нерешенных проблем, низкой самооценки или затруднений в отношениях с окружающими."
      )}
      <Accordion style={{
        backgroundColor: "rgba(240, 213, 253, 1)",
        marginBottom: 5,
        borderRadius: 10,
        color: "rgba(135, 102, 173, 1)"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <b style={{ fontSize: 24, fontFamily: "Golos" }}>Прогнозы по годам</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{width: '100%', padding: 0, margin: 0}}>
          <ForecastForTheYearChildren
            repeat={props.repeat}
            yes={props.yes}
            yaer={yearHandler}
            age={props.age}
            C={props.C}
            A={props.A}
            CGCC={props.CGCC}
            AEAA={props.AEAA}
            CGC={props.CGC}
            AEA={props.AEA}
            CGCCG={props.CGCCG}
            AEAAE={props.AEAAE}
            CG={props.CG}
            AE={props.AE}
            CGGCG={props.CGGCG}
            AEEAE={props.AEEAE}
            CGGG={props.CGGG}
            AEEE={props.AEEE}
            G={props.G}
            E={props.E}
            GDGG={props.GDGG}
            EBEE={props.EBEE}
            GDG={props.GDG}
            EBE={props.EBE}
            GDGGD={props.GDGGD}
            EBEEB={props.EBEEB}
            GD={props.GD}
            EB={props.EB}
            AEE={props.AEE}
            CGG={props.CGG}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
