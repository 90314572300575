import React from "react";
import TextField from "@mui/material/TextField";
import {
  emailValidation,
  messageValidation,
  subjectValidations
} from "../../../../validations/validationRegistrationForm";
import { useAppDispatch, useAppSelector } from "../../../../redux-store/store";
import { SubmitHandler, useForm } from "react-hook-form";
import { authAPI } from "../../../../API/API";
import { HandleError } from "../../../../utils/errors";
import { successAC } from "../../../../redux-store/error-wait-reducer";
import { createTheme, Theme, ThemeProvider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./Support.scss";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "white",
            "--TextField-brandBorderHoverColor": "white",
            "--TextField-brandBorderFocusedColor": "white",
            // "&:hover": {
            //   border: '1px solid var(--TextField-brandBorderHoverColor)',
            //   borderRadius: '10px'
            // },
            "& label": {
              color: "var(--TextField-brandBorderFocusedColor)"
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderRadius: "10px",
            color: "white",
            borderColor: "var(--TextField-brandBorderColor)"
          },
          root: {
            color: "white"
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: 'white',
            '&::before': {
              borderBottom: '2px solid var(--TextField-brandBorderColor)',
            },
            '&:hover': {
              borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
    }
  });

interface FormRegistration {
  email: string;
  subject: string;
  message: string;
}

export const Support = () => {
  const email = useAppSelector((state) => state.registrationReducer.email);
  const dispatch = useAppDispatch();
  const outerTheme = useTheme();
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    reset
  } = useForm<FormRegistration>({
    mode: "all"
  });

  const onSubmit: SubmitHandler<FormRegistration> = async (data) => {
    try {
      const res = await authAPI.supportMessage({
        email: data.email,
        subject: data.subject,
        message: data.message
      });
      dispatch(successAC({ success: res.data.message }));
      reset({ subject: "", message: "" });
    } catch (e) {
      HandleError(e, dispatch);
    }
  };

  return (
    <div className={"userProfile"}>
      <span className={"spanTarifActive"}>Отправить сообщение в поддержку</span>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"divSupport"}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              className={"formPassAndMail"}
              {...register("email", emailValidation)}
              label="Ваш Email"
              defaultValue={email}
              error={!!errors.email?.message}
              helperText={errors.email?.message}
            />
          </ThemeProvider>
        </div>
        <div className={"divSupport"}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              {...register("subject", subjectValidations)}
              className={"formPassAndMail"}
              label="Тема сообщения"
              error={!!errors.subject?.message}
              helperText={errors.subject?.message}
            />
          </ThemeProvider>
        </div>
        <div className={"divSupport"}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              {...register("message", messageValidation)}
              className={"formPassAndMail"}
              label="Ваше сообщение"
              multiline
              rows={5}
              error={!!errors.message?.message}
              helperText={errors.message?.message}
            />
          </ThemeProvider>
        </div>
        <button
          style={{ fontWeight: 'bold', fontSize: '16px', width: '90%' }}
          className={`baton + ' ' + ${!isValid ? "dis" : ""}`}
          disabled={!isValid}
          type={"submit"}
        >
          Отправить
        </button>
      </form>
    </div>
  );
};
