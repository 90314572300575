import React from "react";
import FormControl from "@mui/material/FormControl";
import { passValidation } from "../validations/validationRegistrationForm";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import FormHelperText from "@mui/material/FormHelperText";
import { SubmitHandler, useForm } from "react-hook-form";
import { resetPasswordThunk } from "../redux-store/registration-login-auth";
import { useAppDispatch, useAppSelector } from "../redux-store/store";
import { CircularProgress, createTheme, Theme, ThemeProvider } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import mark from "../check-mark-success.png";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
      primary: {
        main: "#fff"
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            color: "white",
            "--TextField-brandBorderColor": "white",
            "--TextField-brandBorderHoverColor": "white",
            "--TextField-brandBorderFocusedColor": "white",
            "& label.Mui-focused": {
              color: "white",
              fontFamily: "Golos"
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: "white",
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)"
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)"
            },
            fontFamily: "Golos"
          }
        }
      }
    }
  });

interface FormRegistration {
  password: string;
  confirmpass: string;
}

export const ResetPassword = () => {
  const outerTheme = useTheme();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const status = useAppSelector((state) => state.errorReducer.status);
  const params = useParams();

  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    getValues
  } = useForm<FormRegistration>({
    mode: "all"
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const password = getValues("password");
  const onSubmit: SubmitHandler<FormRegistration> = (data) => {
    dispatch(
      resetPasswordThunk({
        password: data.password,
        token: params.token ? params.token : "d"
      })
    );
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className={"forgot"}>
      {status === "loading" ? (
        <CircularProgress />
      ) : status === "success" ? (
        <div className={"forgotBox"} >
          <span className={"spanTarifActive"}>
            Пароль успешно изменен
            пароля
          </span>
          <img src={mark} alt="Успешно" />
          <Link
            style={{ fontSize: 25, marginTop: 20 }}
            className={"batonStandart"}
            to={"/welcome"}
          >
          Войти в профиль с новым паролем
          </Link>
        </div>
      ) : (
        <form className={"forgotBox"} onSubmit={handleSubmit(onSubmit)}>
          <span className={"spanTarifActive"}>Введите свой новый пароль</span>
          <div className={"divLogin"}>
            <FormControl className={"forgotEmail"} variant="standard">
              <ThemeProvider theme={customTheme(outerTheme)}>
                <TextField
                  InputLabelProps={{ style: { color: "#fff" } }}
                  label={"Введите новый пароль"}
                  variant={"standard"}
                  error={!!errors.password?.message}
                  {...register("password", passValidation)}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff style={{ color: "rgba(104, 74, 134, 1)" }} /> :
                          <Visibility style={{ color: "rgba(104, 74, 134, 1)" }} />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </ThemeProvider>
              {!errors.password?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
                <FormHelperText error={!!errors.password?.message}>
                  {errors.password?.message}
                </FormHelperText>}
            </FormControl>
          </div>
          <div className={"divLogin"}>
            <FormControl className={"forgotEmail"} variant="standard">
              <ThemeProvider theme={customTheme(outerTheme)}>
                <TextField
                  InputLabelProps={{ style: { color: "#fff" } }}
                  label={"Повторите новый пароль"}
                  variant={'standard'}
                  error={!!errors.confirmpass?.message}
                  {...register("confirmpass", {
                    validate: {
                      passConf: (value: string) => {
                        if (password !== value) {
                          return "Пароли не совпадают";
                        }
                        return true;
                      }
                    }
                  })}
                  type={showPassword1 ? "text" : "password"}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword1 ? <VisibilityOff style={{ color: "rgba(104, 74, 134, 1)" }} /> : <Visibility style={{ color: "rgba(104, 74, 134, 1)" }} />}
                        </IconButton>
                      </InputAdornment>
                  }
                  }

                />
              </ThemeProvider>
              {!errors.confirmpass?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
                <FormHelperText error={!!errors.confirmpass?.message}>
                  {errors.confirmpass?.message}
                </FormHelperText>}
            </FormControl>
          </div>
          <button
            style={{ padding: "5px 40px" }}
            className={`batonStandart + ' ' + ${!isValid ? "dis" : ""}`}
            disabled={!isValid}
            type={"submit"}
          >
            Отправить
          </button>
        </form>
      )}
    </div>
  );
};
