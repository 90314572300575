import React, { useEffect } from "react";
import "./Blog.scss";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux-store/store";
import { getBlog } from "../../redux-store/blog-reducer";
import { CircularProgress } from "@mui/material";

export const Blog = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const blog = useAppSelector((state) => state.blogReducer.data);
  const wait = useAppSelector((state) => state.errorReducer.tableWait);

  useEffect(() => {
    if (id) {
      dispatch(getBlog({ id }));
    }
  }, [id, dispatch]);

  const createLinkifiedText = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part: string, idx: number) => {
      if (part.match(urlRegex)) {
        return (
          <a style={{color: 'white'}} key={idx} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className="glavDivBlog">
      {wait ? (
        <div
          style={{
            position: "fixed",
            top: "30%",
            textAlign: "center",
            width: "100%",
            height: "100vh !important",
          }}
        >
          <CircularProgress style={{ color: "white" }} />
        </div>
      ) : (
        <React.Fragment>
          <span className="titleBlog">{blog?.title}</span>
          <img className="imgBlogOnce" src={blog?.img} alt="Блог" />
          <p className="textBlog">
            {blog?.text.split("\n").map((paragraph: string, idx: number) => (
              <React.Fragment key={idx}>
                {createLinkifiedText(paragraph)}
                <br />
              </React.Fragment>
            ))}
          </p>
        </React.Fragment>
      )}
    </div>
  );
};











