import React from "react";
import "./Tarif.scss";
import { useAppSelector } from "../../../../redux-store/store";
import { HashLink as Anchor } from "react-router-hash-link";
import tarif1 from "../../../../tariff-icons1.png";
import tarif30 from "../../../../tariff-icons30.png";
import tarif365 from "../../../../tariff-icons365.png";
import tarifall from "../../../../tariff-iconsall.png";
import SchoolIcon from "@mui/icons-material/School";
import { Link } from "react-router-dom";

export const Tarif = () => {
  const subscribe = useAppSelector(
    (state) => state.registrationReducer.subscription
  );

  // eslint-disable-next-line array-callback-return
  const tarif = subscribe?.map((el: any) => {
    if (el?.access) {
      if (el.subscribe === "Пробный") {
        return (
          <div className={"divTariff"}>
            <img src={tarif1} alt="" />
            <div className={"divTarifJst"}>
              <span style={{ fontSize: 24, fontWeight: "bold" }}>Пробный тариф</span>
              <span>{`Остаток: ${el.quantity} матрица любого типа`}</span>
              <Link
                className={"batonTarif"}
                to={"/pay"}
                state={{ name: "Пробный", price: 29000 }}
              >
                Купить повторно
              </Link>
            </div>
          </div>
        );
      } else if (el.subscribe === "Матрица на месяц") {
        let timestamp = new Date(el.expiresSub);
        let day = timestamp.getDate();
        let month = timestamp.getMonth() + 1;
        let year = timestamp.getFullYear();
        return (
          <div className={"divTariff"}>
            <img src={tarif30} alt="" />
            <div className={"divTarifJst"}>
              <span style={{ fontSize: 24, fontWeight: "bold" }}>Тариф на месяц</span>
              <span>{`Истекает: ${
                day ? (day > 9 ? day : "0" + day) : undefined
              }-${month ? (month > 9 ? month : "0" + month) : undefined}-${
                year ? year : undefined
              }`}</span>
              <Link
                className={"batonTarif"}
                to={"/pay"}
                state={{ name: "Матрица на месяц", price: 129000 }}
              >
                Купить повторно
              </Link>
            </div>
          </div>
        );
      } else if (el.subscribe === "Матрица на год") {
        let timestamp = new Date(el.expiresSub);
        let day = timestamp.getDate();
        let month = timestamp.getMonth() + 1;
        let year = timestamp.getFullYear();
        return (
          <div className={"divTariff"}>
            <img src={tarif365} alt="" />
            <div className={"divTarifJst"}>
              <span style={{ fontSize: 24, fontWeight: "bold" }}>Тариф на год</span>
              <span>{`Истекает: ${
                day ? (day > 9 ? day : "0" + day) : undefined
              }-${month ? (month > 9 ? month : "0" + month) : undefined}-${
                year ? year : undefined
              }`}</span>
              <Link
                className={"batonTarif"}
                to={"/pay"}
                state={{ name: "Матрица на год", price: 299000 }}
              >
                Купить повторно
              </Link>
            </div>
          </div>
        );
      } else if (el.subscribe === "Вместе и навсегда") {
        return (
          <div className={"divTariff"}>
            <img src={tarifall} alt="" />
            <div className={"divTarifJst"}>
              <span style={{ fontSize: 24, fontWeight: "bold" }}>Тариф навсегда</span>
              <span>{`Истекает: никогда`}</span>
              <Link
                className={"batonTarif"}
                to={"/pay"}
                state={{ name: "Вместе и навсегда", price: 699000 }}
              >
                Купить повторно
              </Link>
            </div>
          </div>
        );
      } else if (el.subscribe === "Базовый курс + Вместе и навсегда") {
        return (
          <div className={"divTariff"}>
            <div className={"twoIcon"}>
              <div className={"iconDiv"}><SchoolIcon style={{ fontSize: 70, margin: "0 auto", height: 100 }} /></div>
              <img src={tarifall} alt="" />
            </div>
            <div className={"divTarifJst"}>
              <span style={{ fontSize: 24, fontWeight: "bold" }}>Базовый курс + Вместе и навсегда</span>
              <span>{`Истекает: никогда`}</span>
            </div>
          </div>
        );
      } else if (el.subscribe === "Свой сайт-калькулятор по Матрице судьбы") {
        return (
          <div className={"divTariff"}>
              <div className={"iconDiv"}><SchoolIcon style={{ fontSize: 70, margin: "0 auto", height: 100 }} /></div>
            <div className={"divTarifJst"}>
              <span style={{ fontSize: 24, fontWeight: "bold" }}>Свой сайт-калькулятор по Матрице судьбы</span>
              {el.url ? <Link
                className={"batonTarif"}
                to={el.url}
              >
                {el.url}
              </Link> : <span>Здесь будет ссылка на Ваш сайт</span>}
            </div>
          </div>
        );
      }
      else if (el.subscribe === "Курс матрицы совместимости" || el.subscribe === "Курс детской матрицы" || el.subscribe === "Базовый курс матрицы" || el.subscribe === "Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца") {
        return (
          <div className={"divTariff"}>
            <div className={"iconDiv"}><SchoolIcon style={{ fontSize: 70, margin: "0 auto", height: 100, width: 100 }} /></div>
            <div className={"divTarifJst"}>
              <span style={{ fontSize: 24, fontWeight: "bold" }}>{el.subscribe}</span>
              <span>{`Истекает: никогда`}</span>
            </div>
          </div>
        );
      }
    } else {
      return undefined;
    }
  });


  return (
    <div className={"userProfile"}>
      <span className={"spanTarifActive"}>Действующие тарифы</span>
      {!subscribe?.filter((e: any) => e.access).length ? (
        <div className={"divTarifNone"}>
          <span className={"spanTarifNone"}>У вас нет действующих тарифов</span>
          <Anchor
            className={"batonTarif"}
            smooth
            to={"/#tarif"}
            style={{
              color: "rgba(46, 32, 81, 1)",
              backgroundColor: "rgba(196, 157, 214, 1)",
              margin: "10px 0",
              padding: "5px 50px"
            }}
          >
            Приобрести тариф
          </Anchor>
        </div>
      ) : (
        <div className={"divTarifNone"}>{tarif}</div>
      )}
    </div>
  );
};
