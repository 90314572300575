import React, { useState } from "react";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import "./LearnPay.scss";
import sertificateImage from "../sertificate.png";
import diplom from "../diplom.png";
import star from "../bullet-star.png";
import mark from "../check-mark.png";
import site from "../site.png";
import { useAppSelector } from "../redux-store/store";
import pdfUrl from "../doc.pdf"; // Укажите путь к вашему PDF

export const LearnPay = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const auth = useAppSelector((state) => state.registrationReducer.auth);
  const openModal = (imageUrl: any) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const [isDisabled, setIsDisabled] = useState(true);
  const closeModal = () => {
    setSelectedImage("");
    setModalOpen(false);
  };
  return (
    <div className={"learnDiv"}>
      <span className={"spanLearn"}>Программы обучения</span>
      <div className={"learnProgram"}>
        <div className={"learnDescription"} id={"LearnDesck"}>
          <span className={"learnSpanKourse"}>
            Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца + Матрица на год
          </span>
          <p className={"learnP"}>
            <span className={"spanStar"}>Дисциплины:</span>
            <span className={"spanStar"}><img src={star} alt="" />Основы метода «Матрица Судьбы»С 1 по 7 урок </span>
            <span className={"spanStar"}><img src={star} alt="" />Энергии в Матрице СудьбыС 8 по 32 урок</span>
            <span className={"spanStar"}><img src={star} alt="" />Основы техники и анализа точек в Матрице СудьбыС 33 по 43 урок </span>
            <span className={"spanStar"}><img src={star}
                                              alt="" />Методика работы с Матрицей здоровьяС 44 по 45 урок</span>
            <span className={"spanStar"}><img src={star} alt="" />Методы прогнозирования в Матрице СудьбыС 46 по 48 урок</span>
            <span className={"spanStar"}><img src={star} alt="" />Иерархия в Матрице судьбыС 49 по 52 урок</span>
            <span className={"spanStar"}><img src={star} alt="" />Энергоемкие арканы в Матрице судьбы53 урок </span>
            <span className={"spanStar"}><img src={star} alt="" />Детская Матрица судьбыС 54 по 56 урок</span>
            <span className={"spanStar"}><img src={star} alt="" />Матрица совместимости партнеровС 57 по 58 урок </span>
            <span className={"spanStar"}><img src={star} alt="" />Позиционирование в социальных сетях через Матрицу судьбы59 урок </span>
            <span className={"spanStar"}><img src={star} alt="" />Зеркальная , закрытая матрица и энергии антиподыС 60 по 62 урок</span>
            <span className={"spanStar"}><img src={star} alt="" />Основы консультирования клиентовС 63 по 67 урок</span>
            <span style={{ fontWeight: "bold" }} className={"spanStar"}>Итоговый контроль для получения диплома</span>
            <span style={{ fontWeight: "bold" }} className={"spanStar"}>‼️ВАЖНО: Диплом о профессиональной переподготовке выдается на базе уже имеющегося у вас средне-специального или высшего образования.</span>
            <span style={{ fontWeight: "bold" }} className={"spanStar"}>‼️Потребуется предоставление скриншота документа.</span>
            <span style={{ fontWeight: "bold" }} className={"spanStar"}>‼️Доступ к матрице на год.</span>
            <a style={{ fontWeight: "bold", color: 'white' }} className={"spanStar"} href={pdfUrl}
                target="_blank" rel="noopener noreferrer">
              ‼️Свидетельство
          </a>
          </p>
          <div>
          <span className={"spanLearn"}>
            Такой диплом вы получаете после окончания данного курса
          </span>
            <div className={"imgDiv"}>
              <img
                className={"imgSertificate"}
                src={diplom}
                alt="Диплом"
                onClick={() => openModal(diplom)}
              />
            </div>
            {modalOpen && (
              <div className="modal-container">
                <div className="modal-overlay" onClick={closeModal}></div>
                <div className="modal-content">
                  <img src={selectedImage} alt="Full-screen" />
                </div>
              </div>
            )}
          </div>
          <div className={"divBoxPay"}>
            <span className={"price"} style={{ textDecoration: "line-through" }}>27 900 <p>рублей</p></span>
            <span className={"price"}>19 900 <p>рублей</p></span>
            <Link
              style={{ fontWeight: "bold", fontSize: 24, width: "100%" }}
              className={"baton"}
              to={"/pay"}
              state={{
                name: "Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца",
                price: 1990000
              }}
            >
              Приобрести
            </Link>
          </div>
        </div>


        <div className={"learnDescription"} id={"Land"}>
          <span className={"learnSpanKourse"}>
            Программа тарифа по покупке своего сайта-калькулятор по матрице судьбы
          </span>
          <p className={"learnP"}>
            <span className={"spanStar"}><img src={star} alt="" />1. Разработка сайта-калькулятора:
Этот тариф включает в себя приобретение качественного и стильного сайта-калькулятора , который отобразит ваш бренд и предоставит возможность вашим пользователям самостоятельно рассчитать свою матрицу судьбы ,а также покажет полезную информацию о ваших услугах или продуктах.
</span>
            <span className={"spanStar"}><img src={star} alt="" />2. Калькулятор по матрице судьбы интегрирован в ваш сайт-калькулятор. Этот инструмент поможет не терять ваших пользователей ,которые начинают интересоваться данным методом самопознания и быть конкурентно способным на рынке услуг.
</span>
            <span className={"spanStar"}><img src={star} alt="" />3. Целенаправленное продвижение: Данный способ также включает возможности стратегии целенаправленного продвижения вашего сайта-калькулятора, чтобы привлечь больше потенциальных клиентов и улучшить видимость вашего бренда в онлайн-среде.
</span>
            <span style={{ fontWeight: "bold" }} className={"spanStar"}>Этот тариф предоставляет вам комплексное решение для создания, развития и успешного продвижения ваших услуг с помощью вашего сайта-калькулятора.</span>
            <Link target="_blank" className={"spanStar"} style={{ fontWeight: "bold", color: "white" }}
                  to={"https://priemihovamatrix.ru/"}>Пример сайта, жми сюда</Link>
          </p>
          <div style={{ zIndex: 1 }}>
          <span className={"spanLearn"}>
            Пример того, как будет выглядеть Ваш сайт
          </span>
            <div className={"imgDiv"}>
              <img
                className={"imgSertificate"}
                src={site}
                alt="Сертификат"
                onClick={() => openModal(site)}
              />
            </div>
            {modalOpen && (
              <div className="modal-container">
                <div className="modal-overlay" onClick={closeModal}></div>
                <div className="modal-content">
                  <img src={selectedImage} alt="Full-screen" />
                </div>
              </div>
            )}
          </div>
          <div className={"divBoxPay"}>
            <span className={"price"}>6 990 <p>рублей</p></span>
            <Link
              style={{ fontWeight: "bold", fontSize: 24, width: "100%", pointerEvents: isDisabled ? 'none' : 'auto', opacity: isDisabled ? 0.5 : 1 }}
              className={"baton"}
              to={`/${auth ? "payland" : "welcome"}`}
              state={{
                name: "Свой сайт-калькулятор по Матрице судьбы",
                price: 699000
              }}
            >
              Скоро
            </Link>
          </div>
        </div>


        <div className={"learnDescription"}>
          <span className={"learnSpanKourse"}>
            Базовый курс по Матрицы судьбы
          </span>
          <p className={"learnP"}>
            <span className={"spanStar"}><img src={star} alt="" />Расчет матрицы судьбы</span>
            <span className={"spanStar"}><img src={star} alt="" />Погружение в три семерицы энергии 1-22 аркан</span>
            <span className={"spanStar"}><img src={star} alt="" />Карма и виды карм:</span>
            <span style={{ marginLeft: 36 }}>&mdash; Детско-родительская карма</span>
            <br />
            <span style={{ marginLeft: 36 }}>&mdash; Родовые программы</span>
            <br />
            <span style={{ marginLeft: 36 }}>&mdash; Кармический хвост</span>
            <br />
            <span style={{ marginLeft: 36 }}>&mdash; Программы в матрице судьбы</span>
            <br />
            <span className={"spanStar"}><img src={star} alt="" />Предназначение</span>
            <span className={"spanStar"}><img src={star} alt="" />Деньги в матрице судьбы</span>
            <span className={"spanStar"}><img src={star} alt="" />Таланты</span>
            <span className={"spanStar"}><img src={star} alt="" />Отношения в матрице судьбы</span>
            <span className={"spanStar"}><img src={star} alt="" />Портрет вашего идеального партнера</span>
            <span className={"spanStar"}><img src={star} alt="" />Карта здоровья в матрице</span>
            <span className={"spanStar"}><img src={star} alt="" />Методы прогнозирования в матрице судьбы</span>
            <span className={"spanStar"}><img src={star} alt="" />Зеркальные каналы в матрице</span>
            <span className={"spanStar"}><img src={star} alt="" />Урок по консультациям</span>
            <span style={{ fontWeight: "bold" }} className={"spanStar"}>По окончанию курса получаете сертификат</span>
          </p>
          <div>
          <span className={"spanLearn"}>
            Такой сертификат вы получаете после окончания данного курса
          </span>
            <div className={"imgDiv"}>
              <img
                className={"imgSertificate"}
                src={sertificateImage}
                alt="Сертификат"
                onClick={() => openModal(sertificateImage)}
              />
            </div>
            {modalOpen && (
              <div className="modal-container">
                <div className="modal-overlay" onClick={closeModal}></div>
                <div className="modal-content">
                  <img src={selectedImage} alt="Full-screen" />
                </div>
              </div>
            )}
          </div>
          <div className={"divBoxPay"}>
            <span className={"price"}>3 590 <p>рублей</p></span>
            <Link
              style={{ fontWeight: "bold", fontSize: 24, width: "100%" }}
              className={"baton"}
              to={"/pay"}
              state={{
                name: "Базовый курс матрицы",
                price: 359000
              }}
            >
              Приобрести
            </Link>
          </div>
        </div>
        <div className={"learnDescription"}>
          <span className={"learnSpanKourse"}>Урок по детской матрице</span>
          <p className={"learnP"}>
            <span className={"spanStar"} style={{ fontWeight: "bold" }}>Дополнительный тариф предлагает приобретение видео урока "Особенности разбора Детской матрицы" и методички,
            включая:</span>
            <span className={"spanStar"}><img src={star} alt="" />"Расшифровку Детской матрицы"</span>
            <span className={"spanStar"}><img src={star} alt="" />"Детско-родительские отношения" и анкету для родителей.</span>
            <span className={"spanStar"} style={{ fontWeight: "bold" }}>Методичка "Расшифровка Детской матрицы" содержит описание 22 энергий, адаптированные под детскую матрицу, негативные сценарии, навязанные родителями, и рекомендации для родителей относительно кружков и секций.</span>
            <span className={"spanStar"} style={{ fontWeight: "bold" }}>Методичка "Детско-родительские отношения" включает информацию о том, чему пришел научить ребенок, какие ошибки по отношению к родителям и детям могут быть совершены, и к чему важно прийти. </span>
            <span className={"spanStar"}
                  style={{ fontWeight: "bold" }}>Тариф также включает анкету для родителей.</span>
            <span className={"spanStar"}><img src={star} alt="" />Также просмотр детской матрицы через чакральную систему </span>
            <span className={"spanStar"}
                  style={{ fontWeight: "bold" }}>*Приобретается дополнительно к базовому обучению</span>
          </p>
          <div className={"divBoxPay"}>
            <span className={"price"}>2 500 <p>рублей</p></span>
            <Link
              style={{ fontWeight: "bold", fontSize: 24, width: "100%" }}
              className={"baton"}
              to={"/pay"}
              state={{
                name: "Курс детской матрицы",
                price: 250000
              }}
            >
              Приобрести
            </Link>
          </div>
        </div>
        <div className={"learnDescription"}>
          <span className={"learnSpanKourse"}>
            Урок по матрице совместимости
          </span>
          <p className={"learnP"}>
            <span className={"spanStar"}><img src={star} alt="" />Данный тариф включает в себя видео урок "Расчет матрицы совместимости и анализа матрицы совместимости"</span>
            <span className={"spanStar"}><img src={star} alt="" />А также методички , содержащую "Расшифровку матрицы совместимости с рекомендациями для проработки энергий на каждой позиции".</span>
            <span className={"spanStar"}
                  style={{ fontWeight: "bold" }}>*Приобретается дополнительно к базовому обучению</span>
          </p>
          <div className={"divBoxPay"}>
            <span className={"price"}>2 500 <p>рублей</p></span>
            <Link
              style={{ fontWeight: "bold", fontSize: 24, width: "100%" }}
              className={"baton"}
              to={"/pay"}
              state={{
                name: "Курс матрицы совместимости",
                price: 250000
              }}
            >
              Приобрести
            </Link>
          </div>
        </div>
      </div>
      <div className={"backColor otstup"}>
        <div className={"allPay"}>
          <div className={"listLearn"}>
            <Card className={"cardLearn"}>
              <span className={"spanLearnPay"}>Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца + Матрица на год</span>
              <span className={"price"} style={{ textDecoration: "line-through", flexDirection: "column" }}>27 900 <p
                style={{ fontSize: 24, fontFamily: "Golos" }}>рублей</p></span>
              <span className={"price"} style={{ flexDirection: "column" }}>19 900 <p
                style={{ fontSize: 24, fontFamily: "Golos" }}>рублей</p></span>
              <ul>
                <li className={"ulPay"}>
                  <img src={mark} alt="" className={"ic"} />
                  Вы получаете программу онлайн-курса по Матрице Судьбы с получением диплома государственного образца
                </li>
                <li className={"ulPay"}>
                  <img src={mark} alt="" className={"ic"} />
                  Доступ к матрице на год
                </li>
              </ul>
              <div className={"divBoxPay"}>
                <Link
                  style={{ backgroundColor: "rgba(196, 157, 214, 1)", width: "100%" }}
                  className={"batonStandart"}
                  to={"/pay"}
                  state={{
                    name: "Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца + Матрица на год",
                    price: 1990000
                  }}
                >
                  Приобрести
                </Link>
              </div>
            </Card>
            <Card className={"cardLearn"}>
              <span className={"spanLearnPay"}>Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца + Матрица навсегда</span>
              <span className={"price"} style={{ flexDirection: "column" }}>25 390 <p
                style={{ fontSize: 24, fontFamily: "Golos" }}>рублей</p></span>
              <ul>
                <li className={"ulPay"}>
                  <img src={mark} alt="" className={"ic"} />
                  Вы получаете программу онлайн-курса по Матрице Судьбы с получением диплома государственного образца
                </li>
                <li className={"ulPay"}>
                  <img src={mark} alt="" className={"ic"} />
                  Доступ к калькулятору навсегда
                </li>
              </ul>
              <div className={"divBoxPay"}>
                <Link
                  style={{ backgroundColor: "rgba(196, 157, 214, 1)", width: "100%" }}
                  className={"batonStandart"}
                  to={"/pay"}
                  state={{
                    name: "Программа онлайн-курса по Матрице Судьбы с получением диплома государственного образца + Матрица навсегда",
                    price: 2539000
                  }}
                >
                  Приобрести
                </Link>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
