import React, { useEffect } from "react";
import "./BlogAll.scss";
import { useAppDispatch, useAppSelector } from "../../redux-store/store";
import { getAllBlog } from "../../redux-store/blog-admin-reducer";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import star from "../../bullet-star.png";

export const BlogAll = () => {
  const dispatch = useAppDispatch();
  const allBlog = useAppSelector((state) => state.blogAdminReducer.data);
  const wait = useAppSelector((state) => state.errorReducer.tableWait);

  useEffect(() => {
    dispatch(getAllBlog());
  }, [dispatch]);

  const blogs = () => {
    return allBlog.map((el, index) => (
      <Link
        to={`/blog/${el._id}`}
        className="divBlog"
        key={index}
      >
        <div className="imgBlog">
          <img style={{ borderRadius: '20px', width: '100%' }} src={el.img} alt="Блог" />
        </div>
        <span className="spanBlog">{el.title}</span>
      </Link>
    ));
  };

  return (
    <div className="glavDivBlog">
      {wait ? (
        <div
          style={{
            position: "fixed",
            top: "30%",
            textAlign: "center",
            width: "100%",
            height: "100vh !important"
          }}
        >
          <CircularProgress style={{ color: "white" }} />
        </div>
      ) : (
        <React.Fragment>
          <span className="spanGlavBlog">
            <img style={{ marginRight: 10 }} src={star} alt="" />
            Все статьи
            <img style={{ marginLeft: 10 }} src={star} alt="" />
          </span>
          <div className="divBlogs">{blogs()}</div>
        </React.Fragment>
      )}
    </div>
  );
};

