import React, { useState } from "react";
import Box from "@mui/material/Box";
import "./Welcome.scss";
import { Link, Navigate } from "react-router-dom";
import { useAppSelector } from "../../redux-store/store";
import { Login } from "../../feature/Login";
import { RegistrationForm } from "../../feature/RegistrationForm";
import logotip from "../../logo-icon.png";

export const Welcome = () => {
  const auth = useAppSelector((state) => state.registrationReducer.auth);
  const [value, setValue] = useState(false);

  if (auth) {
    return <Navigate to={"/profile/user"} />;
  }

  return (
    <Box className={"login"}>
      <div className={"backImageKosmosWelcome"}></div>
      <div className={"formLogin"}>
        <Link to={"/"}>
          <img src={logotip} alt="Логотип" />
        </Link>
        <span className={"spanWelcome"}>добро пожаловать</span>
        <div className={"knopki"}>
          <button
            style={{
              backgroundColor: !value ? "rgba(196, 157, 214, 1)" : "#2E2051",
              color: !value ? "rgba(46, 32, 81, 1)" : "#fff",
              borderBottom: !value ? 'none' : ''
            }}
            className={"batonWelcome"}
            onClick={() => setValue(false)}
          >
            Войти
          </button>
          <button
            style={{
              backgroundColor: value ? "rgba(196, 157, 214, 1)" : "#2E2051",
              color: value ? "rgba(46, 32, 81, 1)" : "#fff",
              borderBottom: value ? 'none' : ''
            }}
            className={"batonWelcome"}
            onClick={() => setValue(true)}
          >
            Зарегистрироваться
          </button>
        </div>
        {!value ? <Login /> : <RegistrationForm />}
      </div>
    </Box>
  );
};
