import React from "react";
import { Payment } from "./Payment/Payment";
import "../App.scss";
import { DatePickers } from "../feature/DatePickers";
import { HashLink as Anchor } from "react-router-hash-link";
import { Method } from "../feature/Method";

export const Osnova = () => {
  return (
    <>

      <section className={"AppLow"}>
        <div className={"backImageKosmos"}></div>
        <div className={"boxImageGirl"}></div>
        <h1 className={"h1"}>
          <p className={"matrixText"}>МАТРИЦА ТВОЕЙ СУДЬБЫ</p>
          <p className={"h1centerText"}>Узнай о себе больше с помощью матрицы судьбы</p>
          <Anchor smooth to={"/#sectionDateScroll"} className={"batonkd"}>
            Рассчитать
          </Anchor>
        </h1>
      </section>
      <Method />
      <section>
      <DatePickers />
      </section>
      <Payment />
    </>
  );
};
