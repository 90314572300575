import React, { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux-store/store";
import { Link, useLocation } from "react-router-dom";
import { authAPI } from "../../../API/API";
import { HashLink as Anchor } from "react-router-hash-link";
import { Checkbox, createTheme, FormControlLabel, Theme, ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  emailValidation,
  nameAndSurnameValidation,
  passValidation,
  phoneValidation
} from "../../../validations/validationRegistrationForm";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { payAndRegistrationThunk, payAndRegistrationThunkSNG } from "../../../redux-store/registration-login-auth";
import { MuiTelInput } from "mui-tel-input";
import "./Pay.scss";
import { useTheme } from "@mui/material/styles";
import rus from "../../../flag.png";
import mir from "../../../mir.png";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            color: "white",
            "--TextField-brandBorderColor": "white",
            "--TextField-brandBorderHoverColor": "white",
            "--TextField-brandBorderFocusedColor": "white",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)"
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: "white",
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)"
            },

            "&.Mui-disabled": {
              "&::before": {
                borderBottom: "2px solid white"
              }
            },
            fontFamily: "Golos"
          }
        }
      }
    }
  });
const policyFunc = (auth: boolean) => {
  if (auth) {
    return (
      <span style={{ fontSize: 16 }} className={"spanOferta"}>
        Продолжая покупку, я принмаю условия{" "}
        <Link style={{ color: "rgba(196, 157, 214, 1)" }} to={"/oferta"}>Публичной оферты</Link>, подтверждая, что
        ознакомился(ась) с{" "}
        <Anchor style={{ color: "rgba(196, 157, 214, 1)" }} smooth to={"/oferta/#personalData"}>
          Политикой обработки персональных данных
        </Anchor>{" "}
        и даю свое согласие на обработку персональных данных
      </span>
    );
  } else {
    return (
      <span style={{ fontSize: 16 }} className={"spanOferta"}>
        Продолжая покупку и регистрацию, я принмаю условия{" "}
        <Link style={{ color: "rgba(196, 157, 214, 1)" }} to={"/oferta"}>Публичной оферты</Link>, подтверждая, что
        ознакомился(ась) с{" "}
        <Anchor style={{ color: "rgba(196, 157, 214, 1)" }} smooth to={"/oferta/#personalData"}>
          Политикой обработки персональных данных
        </Anchor>{" "}
        и даю свое согласие на обработку персональных данных
      </span>
    );
  }
};

interface FormRegistration {
  name: string;
  surname: string;
  email: string;
  password: string;
  confirmpass: string;
  check: boolean;
  phoneInput: string;
}

export const Pay = () => {
  const outerTheme = useTheme();
  const { state } = useLocation();
  const name = useAppSelector((state) => state.registrationReducer.name);
  const surname = useAppSelector((state) => state.registrationReducer.surname);
  const mail = useAppSelector((state) => state.registrationReducer.email);
  const id = useAppSelector((state) => state.registrationReducer.id);
  const auth = useAppSelector((state) => state.registrationReducer.auth);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const [sng, setSng] = React.useState(false);



  const pol = policyFunc(auth);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    getValues,
    control
  } = useForm<FormRegistration>({
    mode: "all"
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const password = getValues("password");
  const onSubmit: SubmitHandler<FormRegistration> = (data) => {
    dispatch(
      payAndRegistrationThunk({
        nameProduct: state.name,
        price: state.price,
        name: data.name,
        surname: data.surname,
        email: data.email.toLowerCase(),
        password: data.password,
        check: data.check,
        phone: data.phoneInput,
        date: state?.date,
        username: state?.username,
        gender: state?.gender,
        matrix: state?.matrix,
        date1: state?.date1,
        date2: state?.date2
      })
    );
  };


  const onSubmitSNG: SubmitHandler<FormRegistration> = (data) => {
    dispatch(
      payAndRegistrationThunkSNG({
        nameProduct: state.name,
        price: Math.floor(state.price / 100),
        name: data.name,
        surname: data.surname,
        email: data.email.toLowerCase(),
        password: data.password,
        check: data.check,
        phone: data.phoneInput,
        date: state?.date,
        username: state?.username,
        gender: state?.gender,
        matrix: state?.matrix,
        date1: state?.date1,
        date2: state?.date2
      })
    );
  };


  const onChangeCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setCheck(e.currentTarget.checked);
  };
  const onClickTests = async () => {
    await authAPI.matrixOnce({
      id,
      item: {
        name: state.name,
        price: state.price,
        mail,
        date: state?.date,
        username: state?.username,
        gender: state?.gender,
        matrix: state?.matrix,
        date1: state?.date1,
        date2: state?.date2
      }
    });
  };

  const onClickTestsSNG = async () => {
    await authAPI.matrixOnceSNG({
      id,
      item: {
        name: state.name,
        price: Math.floor(state.price / 100),
        mail,
        date: state?.date,
        username: state?.username,
        gender: state?.gender,
        matrix: state?.matrix,
        date1: state?.date1,
        date2: state?.date2
      }
    });
  };

  const total = state.price.toString().replace(/(\d+)00(?=\s|$)/g, "$1,00");
  return auth ? (
    <div className={"paySection"}>
      <span className={"spanWelcome"}>Оформление покупки</span>
      <div className={"divUserForm"}>
        <span className={"spanUser"}>Имя</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
              InputLabelProps={{
                style: {
                  color: "white",
                  fontFamily: "Golos",
                  fontWeight: "bold",
                  paddingLeft: 10
                }
              }}
              multiline
              disabled
              label={name}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>

      <div className={"divUserForm"}>
        <span className={"spanUser"}>Фамилия</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
              InputLabelProps={{
                style: {
                  color: "white",
                  fontFamily: "Golos",
                  fontWeight: "bold",
                  paddingLeft: 10
                }
              }}
              multiline
              disabled
              label={surname}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>

      <div className={"divUserForm"}>
        <span className={"spanUser"}>Email</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
              InputLabelProps={{
                style: {
                  color: "white",
                  fontFamily: "Golos",
                  fontWeight: "bold",
                  paddingLeft: 10
                }
              }}
              multiline
              disabled
              label={mail}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>


      <div className={"divUserForm"}>
        <span className={"spanUser"}>Тариф</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
              InputLabelProps={{
                style: {
                  color: "rgba(196, 157, 214, 1)",
                  fontFamily: "Golos",
                  fontWeight: "bold",
                  paddingLeft: 10
                }
              }}
              multiline
              disabled
              label={state.name}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>

      <div className={"divUserForm"}>
        <span className={"spanUser"}>Стоимость</span>
        <FormControl style={{ width: "100%" }}>
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
              InputLabelProps={{
                style: {
                  color: "rgba(196, 157, 214, 1)",
                  fontFamily: "Golos",
                  fontWeight: "bold",
                  paddingLeft: 10
                }
              }}
              multiline
              disabled
              label={`${total} руб.`}
              variant="standard"
            />
          </ThemeProvider>
          <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
        </FormControl>
      </div>
      <FormControlLabel
        style={{ margin: "15px auto", color: "white" }}
        className={"saveMe"}
        control={<Checkbox onChange={onChangeCheck} style={{
          color: "rgba(104, 74, 134, 1)",
          width: 16,
          height: 16,
          backgroundColor: "white",
          margin: "0 10px 0 0",
          padding: 0,
          borderRadius: 0
        }} defaultChecked={false} />}
        label={pol}
      />
      <button
        style={{
          width: "100%", fontSize: 16, position: "relative",
          margin: "10px auto 10px auto !important",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        className={`batonPay + ' ' + ${!check ? "dis" : ""}`}
        disabled={!check}
        type={"submit"}
        onClick={onClickTests}
      >
        Оплатить для РФ
        <img className={"imgOplata"} src={rus} alt="" />
      </button>
      <button
        style={{
          width: "100%", margin: "0 auto !important", fontSize: 16, position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        className={`batonPay + ' ' + ${!check ? "dis" : ""}`}
        disabled={!check}
        type={"submit"}
        onClick={onClickTestsSNG}
      >
        Оплатить для других стран
        <img className={"imgOplata"} src={mir} alt="" />
      </button>
    </div>
  ) : (
    <Box className={"paySection"}>
      <span className={"spanWelcome"}>Оформление покупки</span>
      <form className={"formLoginPay"} onSubmit={sng ? handleSubmit(onSubmitSNG) : handleSubmit(onSubmit)}>
        <div className={"divUserForm"}>
          <span className={"spanUser"}>Имя</span>
          <FormControl style={{ width: "100%" }}>
            <ThemeProvider theme={customTheme(outerTheme)}>
              <TextField
                InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)", fontFamily: "Golos",
                    paddingLeft: 10 } }}
                {...register("name", nameAndSurnameValidation)}
                label="Введите ваше имя"
                multiline
                variant="standard"
                error={!!errors.name?.message}
              />
            </ThemeProvider>
            {!errors.name?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
              <FormHelperText error={!!errors.name?.message}>
                {errors.name?.message}
              </FormHelperText>}
          </FormControl>
        </div>

        <div className={"divUserForm"}>
          <span className={"spanUser"}>Фамилия</span>
          <FormControl style={{ width: "100%" }}>
            <ThemeProvider theme={customTheme(outerTheme)}>
              <TextField
                InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)", fontFamily: "Golos",
                    paddingLeft: 10 } }}
                {...register("surname", nameAndSurnameValidation)}
                label="Введите вашу фамилию"
                multiline
                variant="standard"
                error={!!errors.surname?.message}
              />
            </ThemeProvider>
            {!errors.surname?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
              <FormHelperText error={!!errors.surname?.message}>
                {errors.surname?.message}
              </FormHelperText>}
          </FormControl>
        </div>

        <div className={"divUserForm"}>
          <span className={"spanUser"}>Телефон</span>
          <FormControl style={{ width: "100%" }}>
            <Controller
              control={control}
              rules={phoneValidation}
              name={"phoneInput"}
              render={({ field, fieldState }) => (
                <ThemeProvider theme={customTheme(outerTheme)}>
                  <MuiTelInput
                    InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)", fontFamily: "Golos",
                        paddingLeft: 10 } }}
                    {...field}
                    onlyCountries={["RU"]}
                    defaultCountry={"RU"}
                    variant="standard"
                    label={"Введите ваш номер телефона"}
                    inputProps={{ maxLength: 16 }}
                    error={fieldState.invalid}
                  />
                </ThemeProvider>
              )}
            ></Controller>
            {!errors.phoneInput?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
              <FormHelperText error={!!errors.phoneInput?.message}>
                {errors.phoneInput?.message}
              </FormHelperText>}
          </FormControl>
        </div>
        <div className={"divUserForm"}>
          <span className={"spanUser"}>Email</span>
          <FormControl style={{ width: "100%" }}>
            <ThemeProvider theme={customTheme(outerTheme)}>
              <TextField
                InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)", fontFamily: "Golos",
                    paddingLeft: 10 } }}
                {...register("email", emailValidation)}
                label="Введите ваш Email"
                multiline
                variant="standard"
                error={!!errors.email?.message}
              />
            </ThemeProvider>
            {!errors.email?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
              <FormHelperText error={!!errors.email?.message}>
                {errors.email?.message}
              </FormHelperText>}
          </FormControl>
        </div>

        <div className={"divUserForm"}>
          <span className={"spanUser"}>Пароль</span>
          <FormControl style={{ width: "100%" }} variant="standard">
            <ThemeProvider theme={customTheme(outerTheme)}>
              <TextField
                InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)", fontFamily: "Golos",
                    paddingLeft: 10 } }}
                variant={"standard"}
                error={!!errors.password?.message}
                label={"Введите ваш пароль"}
                {...register("password", passValidation)}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff style={{ color: "rgba(104, 74, 134, 1)" }} /> :
                        <Visibility style={{ color: "rgba(104, 74, 134, 1)" }} />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </ThemeProvider>
            {!errors.password?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
              <FormHelperText error={!!errors.password?.message}>
                {errors.password?.message}
              </FormHelperText>}
          </FormControl>
        </div>
        <div className={"divUserForm"}>
          <span className={"spanUser"}>Подтверждение пароля</span>
          <FormControl style={{ width: "100%" }} variant="standard">
            <ThemeProvider theme={customTheme(outerTheme)}>
              <TextField
                InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)", fontFamily: "Golos",
                    paddingLeft: 10 } }}
                label={"Введите повторно ваш пароль"}
                variant={"standard"}
                error={!!errors.confirmpass?.message}
                {...register("confirmpass", {
                  validate: {
                    passConf: (value: string) => {
                      if (password !== value) {
                        return "Пароли не совпадают";
                      }
                      return true;
                    }
                  }
                })}
                type={showPassword1 ? "text" : "password"}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword1 ? <VisibilityOff style={{ color: "rgba(104, 74, 134, 1)" }} /> :
                        <Visibility style={{ color: "rgba(104, 74, 134, 1)" }} />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </ThemeProvider>
            {!errors.confirmpass?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
              <FormHelperText error={!!errors.confirmpass?.message}>
                {errors.confirmpass?.message}
              </FormHelperText>}
          </FormControl>
        </div>
        <div className={"divUserForm"}>
          <span className={"spanUser"}>Тариф</span>
          <FormControl style={{ width: "100%" }}>
            <ThemeProvider theme={customTheme(outerTheme)}>
              <TextField
                sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
                InputLabelProps={{
                  style: {
                    color: "rgba(196, 157, 214, 1)",
                    fontFamily: "Golos",
                    fontWeight: "bold",
                    paddingLeft: 10
                  }
                }}
                multiline
                disabled
                label={state.name}
                variant="standard"
              />
            </ThemeProvider>
            <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
          </FormControl>
        </div>

        <div className={"divUserForm"}>
          <span className={"spanUser"}>Стоимость</span>
          <FormControl style={{ width: "100%" }}>
            <ThemeProvider theme={customTheme(outerTheme)}>
              <TextField
                sx={{ color: "rgba(196, 157, 214, 1)", fontWeight: "bold" }}
                InputLabelProps={{
                  style: {
                    color: "rgba(196, 157, 214, 1)",
                    fontFamily: "Golos",
                    fontWeight: "bold",
                    paddingLeft: 10
                  }
                }}
                multiline
                disabled
                label={`${total} руб.`}
                variant="standard"
              />
            </ThemeProvider>
            <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span>
          </FormControl>
        </div>


        <div className={"divLogin"}>
          <FormControlLabel
            className={"saveMe"}
            style={{ color: "white" }}
            control={<Checkbox style={{
              color: "rgba(104, 74, 134, 1)",
              width: 16,
              height: 16,
              backgroundColor: "white",
              margin: "0 10px 0 0",
              padding: 0,
              borderRadius: 0
            }} defaultChecked {...register("check")} />}
            label="Запомнить меня"
          />
        </div>
        <FormControlLabel
          style={{ margin: "0 auto", color: "white" }}
          className={"saveMe"}
          control={<Checkbox style={{
            color: "rgba(104, 74, 134, 1)",
            width: 16,
            height: 16,
            backgroundColor: "white",
            margin: "0 10px 0 0",
            padding: 0,
            borderRadius: 0
          }} onChange={onChangeCheck} defaultChecked={false} />}
          label={pol}
        />
        <button
          style={{
            width: "100%",
            margin: "20px auto 20px auto",
            fontSize: 16,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          className={`batonPay + ' ' + ${!isValid || !check ? "dis" : ""}`}
          disabled={!isValid || !check}
          onClick={() => setSng(false)}
          type={"submit"}
        >
          Зарегистрироваться и оплатить для РФ
          <img className={"imgOplata"} src={rus} alt="" />
        </button>
        <button
          style={{
            width: "100%", margin: "0 auto", fontSize: 16, position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          className={`batonPay + ' ' + ${!isValid || !check ? "dis" : ""}`}
          disabled={!isValid || !check}
          onClick={() => setSng(true)}
          type={"submit"}
        >
          Зарегистрироваться и оплатить для других стран
          <img className={"imgOplata"} src={mir} alt="" />
        </button>
      </form>
    </Box>
  );
};
