import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppSelector } from "../redux-store/store";
import { HashLink as Anchor } from "react-router-hash-link";
import LockIcon from "@mui/icons-material/Lock";
import { v4 } from "uuid";

export function SimpleAccordionCompatibility() {
  const MatrixCompatibility = useAppSelector(
    (state) => state.MatrixCompatibilityReducer
  );
  const accordion = (
    txt: any,
    arr: {
      paragraph: string; value: { text: string; value: number }[],
      colorBall?: string;
      nums?: { nomer: number, clas: string }[];
    }[],
    lock: boolean
  ) => {
    return (
      <Accordion style={{
        backgroundColor: "rgba(240, 213, 253, 1)",
        marginBottom: 5, borderRadius: 10,
        color: "rgba(46, 32, 81, 1)"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {!lock ? (
              <b style={{
                display: "flex",
                fontSize: 24,
                fontFamily: "Golos",
                color: "rgba(135, 102, 173, 1)"
              }}>{txt}</b>
            ) : (
              <b style={{
                display: "flex",
                fontSize: 24,
                alignItems: "center",
                fontFamily: "Golos",
                color: "rgba(135, 102, 173, 1)"
              }}>
                {arr[0].value?.length === undefined ? (
                  <LockIcon style={{ color: "rgba(135, 102, 173, 1)", marginRight: "15px" }} />
                ) : (
                  ""
                )}{" "}
                {txt}
              </b>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {lock ? (
              arr[0].value?.length === undefined ? (
                <React.Fragment>
                  <p style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 24,
                    fontFamily: "Golos",
                    marginBottom: 20,
                    color: "rgba(135, 102, 173, 1)"
                  }}>
                    Будет доступно после оформления одного из тарифов
                  </p>
                  <Anchor smooth to={"/#tarif"} className={"batonStandart"}>
                    ТАРИФЫ
                  </Anchor>
                </React.Fragment>
              ) : (
                <Typography>
                  {arr.map((el) => {
                    return (
                      <React.Fragment key={v4()}>
                        <b style={{ fontSize: 24, color: "rgba(135, 102, 173, 1)" }}>{el.paragraph}</b>
                        <br />
                        {el.value.map((e) => (
                          <React.Fragment key={v4()}>
                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                              {el.colorBall ? <div
                                style={{ fontSize: 16 }}
                                className={el.colorBall}
                              >
                                {e.value}
                              </div> : <div style={{ display: "flex", flexDirection: "column" }}>
                                {el.nums?.map(num => (
                                  num.nomer === e.value ? <div
                                    style={{ fontSize: 16 }}
                                    className={num.clas}
                                  >
                                    {e.value}
                                  </div> : undefined
                                ))}
                              </div>}
                              <div style={{ width: "85%" }}>
                                {e.text.split("\n").map((paragraph) => (
                                  <React.Fragment key={v4()}>
                                    {paragraph}
                                    <br key={v4()} />
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
                            <br />
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    );
                  })}
                </Typography>
              )
            ) :
            (
              <Typography>
                {arr.map((el) => {
                  return (
                    <React.Fragment key={v4()}>
                      <b>{el.paragraph}</b>
                      <br />
                      {el.value.map((e) => (
                        <React.Fragment key={v4()}>
                          <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            {el.colorBall ? <div
                              style={{ fontSize: 16 }}
                              className={el.colorBall}
                            >
                              {e.value}
                            </div> : <div style={{ display: "flex", flexDirection: "column" }}>
                              {el.nums?.map(num => (
                                num.nomer === e.value ? <div
                                  style={{ fontSize: 16 }}
                                  className={num.clas}
                                >
                                  {e.value}
                                </div> : undefined
                              ))}
                            </div>}
                            <div style={{ width: "85%" }}>
                              {e.text.split("\n").map((paragraph) => (
                                <React.Fragment key={v4()}>
                                  {paragraph}
                                  <br key={v4()} />
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                          <br />
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  );
                })}
              </Typography>
            )}
        </AccordionDetails>
      </Accordion>
    )
      ;
  };

  return (
    <div
      className={"simpAccordion"}
      style={{ width: "100%", margin: "0 auto" }}
    >
      {accordion(
        "Для чего встретились",
        [{
          paragraph: "",
          value: MatrixCompatibility.data?.isWhyDidYouMeet,
          colorBall: "-white boll"
        }],
        false
      )}
      {accordion(
        "Духовная суть пары",
        [{
          paragraph: "",
          value: MatrixCompatibility.data.isTheSpiritualEssenceOfTheCouple,
          colorBall: "-white boll"
        }],
        true
      )}
      {accordion(
        "Материальная карма",
        [{
          paragraph: "",
          value: MatrixCompatibility.data.isMaterialKarma,
          colorBall: "-white boll"
        }],
        true
      )}
      {accordion(
        "Духовная карма пары",
        [{
          paragraph: "",
          value: MatrixCompatibility.data.isCouplesSpiritualKarma,
          colorBall: "-white boll"
        }],
        true
      )}
      {accordion(
        "Родовые задачи партнеров (NEW)",
        [{
          paragraph: '',
          value: MatrixCompatibility.data.isGenericTasksOfPartners,
          colorBall: "-white boll"
        }]
        ,
        true
      )}
      {accordion(
        "Реализация денег в паре",
        [{
          paragraph: "",
          value: MatrixCompatibility.data.isSellingMoneyInPairs,
          colorBall: "-white boll"
        }],
        true
      )}
      {accordion(
        "Укрепление отношений в паре",
        [{
          paragraph: "",
          value: MatrixCompatibility.data.isStrengtheningCoupleRelationships,
          colorBall: "-white boll"
        }],
        true
      )}
      {accordion(
        "Предназначение пары",
        [{
          paragraph: "Задачи пары для проработки в отношениях",
          value: MatrixCompatibility.data.isThePurposeOfTheCouple?.isCoupleTasksToWorkThroughInRelationships,
          colorBall: "borderMatrix boll"
        },
          {
            paragraph: "Задачи для реализации пары в социуме",
            value: MatrixCompatibility.data.isThePurposeOfTheCouple?.isTasksForTheRealizationOfACoupleInSociety,
            colorBall: "borderMatrix boll"
          },
          {
            paragraph: "Ресурс пары",
            value: MatrixCompatibility.data.isThePurposeOfTheCouple?.isCoupleResource,
            colorBall: "borderMatrix boll"
          }
        ],
        true
      )}
      {accordion(
        "Зона комфорта пары",
        [{
          paragraph: "",
          value: MatrixCompatibility.data.isCoupleComfortZone,
          colorBall: "-white boll"
        }],
        true
      )}
    </div>
  );
}
