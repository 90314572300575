import React from "react";
import "./Success.scss";
import mark from "./../../../check-mark-success.png";
import { Link } from "react-router-dom";

export const SuccessLand = () => {
  return (
    <div className={"sectionSuccess"}>
      <div className={"forgotBox"}>
        <span className={"spanWelcome"}>Ваша оплата прошла успешно</span>
        <img src={mark} alt="Успешно" />
        <Link to={"/profile/tarif"} style={{fontWeight: 'bold', width: '100%'}} className={"batonPay"}>
          Тут будет ссылка на Ваш сайт
        </Link>
      </div>
    </div>
  );
};
