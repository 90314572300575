import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppSelector } from "../redux-store/store";
import { ForecastForTheYear } from "./ForecastForTheYear";
import { PropsTypeForecastTheYear } from "../type/personalMatrix-type";
import { filterCalcYear } from "../utils/calc";
import { HashLink as Anchor } from "react-router-hash-link";
import LockIcon from "@mui/icons-material/Lock";
import { v4 } from "uuid";
import "./../components/diagram/diagram.scss";

export function SimpleAccordion(props: PropsTypeForecastTheYear) {
  const personalMatrix = useAppSelector((state) => state.personalMatrixReducer);
  const yearHandler = filterCalcYear(
    personalMatrix.data.isYear,
    props.gender ? props.gender : "W"
  );
  const accordion = (
      txt: any,
      arr: {
        paragraph: string;
        value: { text: string; value: number | string }[];
        colorBall?: string;
        nums?: { nomer: number, clas: string }[];
      }[],
      lock: boolean
    ) => {
      return (
        <Accordion style={{
          backgroundColor: "rgba(240, 213, 253, 1)",
          marginBottom: 5, borderRadius: 10,
          color: "rgba(46, 32, 81, 1)"
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {!lock ? (
                <b style={{
                  display: "flex",
                  fontSize: 24,
                  fontFamily: "Golos",
                  color: "rgba(135, 102, 173, 1)"
                }}>{txt}</b>
              ) : (
                <b style={{
                  display: "flex",
                  fontSize: 24,
                  alignItems: "center",
                  fontFamily: "Golos",
                  color: "rgba(135, 102, 173, 1)"
                }}>
                  {arr[0].value?.length === undefined ? (
                    <LockIcon style={{ color: "rgba(135, 102, 173, 1)", marginRight: "15px" }} />
                  ) : (
                    ""
                  )}{" "}
                  {txt}
                </b>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {lock ? (
              arr[0].value?.length === undefined ? (
                <React.Fragment>
                  <p style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 24,
                    fontFamily: "Golos",
                    marginBottom: 20,
                    color: "rgba(135, 102, 173, 1)"
                  }}>
                    Будет доступно после оформления одного из тарифов
                  </p>
                  <Anchor smooth
                          to={"/#tarif"} className={"batonStandart"}>
                    ТАРИФЫ
                  </Anchor>
                </React.Fragment>
              ) : (
                <Typography>
                  {arr.map((el) => {
                    return (
                      <React.Fragment key={v4()}>
                        <b key={v4()} style={{ fontSize: 24, color: "rgba(135, 102, 173, 1)" }}>{el.paragraph}</b>
                        <br key={v4()} />
                        {el.value.map((e) => (
                          <React.Fragment key={v4()}>
                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                              {el.colorBall ? <div
                                style={{ fontSize: 16 }}
                                className={el.colorBall}
                              >
                                {e.value}
                              </div> : <div style={{ display: "flex", flexDirection: "column" }}>
                                {el.nums?.map(num => (
                                  num.nomer === e.value ? <div
                                    style={{ fontSize: 16 }}
                                    className={num.clas}
                                  >
                                    {e.value}
                                  </div> : undefined
                                ))}
                              </div>}
                              <div style={{ width: "85%" }}>
                                {e.text.split("\n").map((paragraph) => (
                                  <React.Fragment key={v4()}>
                                    {paragraph}
                                    <br key={`br-${v4()}`} />
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
                            <br key={v4()} />
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    );
                  })}
                </Typography>
              )
            ) : (
              <Typography>
                {arr.map((el) => {
                  return (
                    <React.Fragment key={v4()}>
                      <b key={v4()} style={{ fontSize: 24, color: "rgba(135, 102, 173, 1)" }}>{el.paragraph}</b>
                      <br key={v4()} />
                      {el.value.map((e) => (
                        <React.Fragment key={v4()}>
                          <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            {el.colorBall ? <div
                              style={{ fontSize: 16 }}
                              className={el.colorBall}
                            >
                              {e.value}
                            </div> : <div style={{ display: "flex", flexDirection: "column" }}>
                              {el.nums?.map(num => (
                                num.nomer === e.value ? <div
                                  style={{ fontSize: 16 }}
                                  className={num.clas}
                                >
                                  {e.value}
                                </div> : undefined
                              ))}
                            </div>}
                            <div style={{ width: "85%" }}>
                              {e.text.split("\n").map((paragraph) => (
                                <React.Fragment key={v4()}>
                                  {paragraph}
                                  <br key={`br-${v4()}`} />
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                          <br key={v4()} />
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  );
                })}
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      );
    }
  ;

  return (
    <div
      className={"simpAccordion"}
      style={{ width: "100%", margin: "0 auto" }}
    >
      {accordion(
        "Личностные качества",
        [
          {
            paragraph: "В позитиве",
            value: personalMatrix.data.isPersonalQualities?.isPositive,
            colorBall: "-purple boll"
          },
          {
            paragraph: "В негативе",
            value: personalMatrix.data.isPersonalQualities?.isNegative,
            colorBall: "-purple boll"
          },
          {
            paragraph: "Общее",
            value: personalMatrix.data.isPersonalQualities?.isGeneral,
            colorBall: "-yellow boll"
          }
        ],
        false
      )}
      {accordion(
        "Таланты",
        [
          {
            paragraph: "Талант от Бога",
            value: personalMatrix.data.isTalents?.isTalentsOfGod,
            nums: [{ nomer: props.B, clas: "-purple boll" }, {
              nomer: props.B1!,
              clas: "-blue boll"
            }, { nomer: props.B2!, clas: "-cyan boll" }]
          },
          {
            paragraph: "Талант от Отца",
            value: personalMatrix.data.isTalents?.isTalentsOfDad,
            colorBall: "-white boll"
          },
          {
            paragraph: "Талант от Матери",
            value: personalMatrix.data.isTalents?.isTalentsOfMother,
            colorBall: "-white boll"
          }
        ],
        true
      )}
      {accordion(
        "Прошлая жизнь",
        [
          {
            paragraph: "",
            value: personalMatrix.data?.isPastLife
          }
        ],
        true
      )}
      <Accordion style={{
        backgroundColor: "rgba(240, 213, 253, 1)",
        marginBottom: 5,
        borderRadius: 10,
        color: "rgba(46, 32, 81, 1)"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <b style={{
              display: "flex",
              fontSize: 24,
              alignItems: "center",
              fontFamily: "Golos",
              color: "rgba(135, 102, 173, 1)"
            }}>
              {personalMatrix.data.isHealth?.Saxasrara === undefined ? (
                <LockIcon style={{ color: "rgba(135, 102, 173, 1)", marginRight: "15px" }} />
              ) : (
                ""
              )}{" "}
              Здоровье
            </b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {personalMatrix.data.isHealth?.Saxasrara === undefined ? (
            <React.Fragment>
              <p style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 24,
                fontFamily: "Golos",
                marginBottom: 20,
                color: "rgba(135, 102, 173, 1)"
              }}>
                Будет доступно после оформления одного из тарифов
              </p>
              <Anchor smooth to={"/#tarif"} className={"batonStandart"}>
                ТАРИФЫ
              </Anchor>
            </React.Fragment>
          ) : (
            <Typography>
              <b>
                Сахасрара - это седьмое и самое высшее энергетическое центр в теле человека,
                расположенное
                на верхушке головы. Оно связано с повышенным уровнем сознания, духовностью, идеалами и
                высшей мудростью. Проблемы в этой области могут проявляться как физические заболевания,
                так и эмоциональные и психологические состояния. Сахасрара - отвечают за головной мозг,
                волосы, верхняя часть черепа.
              </b>
              <br />
              Если у вас возникают проблемы с волосами и верхней частью черепа, это может указывать на
              дисбаланс или блокировку энергии в сахасраре. В результате этого вы можете чувствовать
              беспокойство, быть особенно требовательными к себе и другим, и испытывать трудности в
              понимании вещей без строгой конкретики.
              <br />
              Решение проблемы включает в себя работу над собой и осознание своего духовного пути. Важно
              научиться управлять энергией и привести ее в гармонию в сахасраре. Это можно сделать путем
              медитации, йоги, дыхательных практик и других духовных методик. Также полезно развивать
              наблюдательность и осознанность, чтобы лучше понимать себя и окружающих.
              <br />
              Помимо этого, регулярный режим жизни, здоровое питание, физическая активность и отдых имеют
              большое значение для поддержания общего здоровья. Относитесь к своим проблемам и жизненным
              ситуациям с большей гибкостью и состраданием к себе и другим. Важно также находить свою
              жизненную цель и стремиться к ее достижению, чтобы ощущать смысл и направление в жизни.
              <br />
              {personalMatrix.data.isHealth.Saxasrara.map((e, index) => (
                <React.Fragment key={index}>
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    {<div style={{ display: "flex", flexDirection: "column" }}>
                      {[{ nomer: props.B, clas: "-purple boll" }, {
                        nomer: props.A!,
                        clas: "-purple boll"
                      }, { nomer: props.E!, clas: "forecastCifra" }].map(num => (
                        num.nomer === e.value ? <div
                          style={{ fontSize: 16 }}
                          className={num.clas}
                        >
                          {e.value}
                        </div> : undefined
                      ))}
                    </div>}
                    <div style={{ width: "90%" }}>
                      {e.text.split("\n").map((paragraph: string, innerIndex) => (
                        <React.Fragment key={innerIndex}>
                          {paragraph}
                          <br key={`br-${index}`} />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
              <b>
                Аджна - это шестое энергетическое центр в теле человека, которое находится в области
                между бровями, известное также как "третий глаз". Он связан с интуицией, интеллектом,
                видением, восприятием, познанием и осознанностью. Проблемы в этой области могут
                проявляться
                как физические заболевания, так и эмоциональные и психологические состояния. Аджна -
                отвечают за затылочные и височные доли мозга, глаз, уши, нос, лицо, верхняя челюсть,
                зубы верхней челюсти, зрительный нерв, кора головного мозга.
              </b>
              <br />
              Если у вас возникают проблемы с глазами, ушами, зубами или другими частями лица, это может
              указывать на дисбаланс или блокировку энергии в аджне. Может быть потеря здоровья связана с
              проблемами в затылочной области или проблемами сущности и выражения личности через лицо. В
              культурном контексте это может проявиться в частых протестах против системы, в которой вы
              находитесь, а также в ощущении недостатка логики при общении и взаимодействии с другими.
              <br />
              Решение проблемы включает в себя работу над укреплением и гармонизацией энергии в аджне.
              Медитация и визуализация могут быть полезными методами для активации и уравновешивания этого
              центра энергии. Также полезно развивать интуицию и осознанность через практики, такие как
              йога и дыхательные упражнения.
              <br />
              Помимо этого, обращайте внимание на уход за своим здоровьем, включая правильное уход за
              глазами, ушами и зубами. Сотрудничество с врачами и другими специалистами может помочь в
              диагностике и лечении физических проблем. Также стремитесь к компромиссам в общении,
              развивайте социальные навыки и поищите поддержку в разных сферах жизни, чтобы чувствовать
              себя связанным и находить гармонию в обществе.
              <br />
              {personalMatrix.data.isHealth?.Adjna !== undefined
                ? personalMatrix.data.isHealth.Adjna.map((e, index) => (
                  <React.Fragment key={index}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                      {<div style={{ display: "flex", flexDirection: "column" }}>
                        {[{ nomer: props.A1, clas: "-blue boll" }, {
                          nomer: props.B1!,
                          clas: "-blue boll"
                        }, { nomer: props.K6!, clas: "forecastCifra" }].map(num => (
                          num.nomer === e.value ? <div
                            style={{ fontSize: 16 }}
                            className={num.clas}
                          >
                            {e.value}
                          </div> : undefined
                        ))}
                      </div>}
                      <div style={{ width: "90%" }}>
                        {e.text.split("\n").map((paragraph: string, innerIndex) => (
                          <React.Fragment key={innerIndex}>
                            {paragraph}
                            <br key={`br-${index}`} />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <br />
                  </React.Fragment>
                ))
                : ""}
              <b>
                Вишудха - это пятый энергетический центр в теле человека, который находится в области
                горла.
                Он связан с коммуникацией, самовыражением, правдивостью, истинной сущностью и творческим
                выражением. Проблемы в этой области могут проявляться как физические заболевания, так и
                эмоциональные и психологические состояния. Вишудха - отвечают за щитовидная железа,
                трахея, бронхи, горло, голосовые связки, плечи,
                руки, седьмой шейный позвонок, все шейные позвонки, нижняя челюсть, зубы нижней челюсти.
              </b>
              <br />
              Если у вас часто повторяются заболевания горла, возникают проблемы со щитовидной железой,
              нижней челюстью, болями в плечах или руках, это может указывать на дисбаланс или блокировку
              энергии в вишудхе. Ваше поведение, описанное вами как неумение выражать мысли,
              некоммуникабельность или перебивание собеседника, может быть связано с проблемами в этой
              области.
              <br />
              Решение проблемы включает в себя работу над укреплением и гармонизацией энергии в вишудхе.
              Здоровый образ жизни, правильное питание и забота о голосе, горле и щитовидной железе могут
              способствовать улучшению физического состояния.
              <br />
              При работе с вишудхой также полезно развивать навыки коммуникации и самовыражения. Практики,
              такие как аффирмации, цветотерапия, медитация, йога и речевое развитие могут помочь в
              улучшении способности выражать мысли и честно общаться с окружающими. Помните, что искреннее
              выражение себя и поддержка других в их самовыражении могут способствовать гармонии в общении
              и взаимодействии.
              <br />
              Обращайтесь также к специалистам, таким как врачи, ортодонты или хирурги, для диагностики и
              лечения специфических проблем с щитовидной железой, зубами или болями в плечах или руках.
              <br />
              {personalMatrix.data.isHealth?.Vishydha !== undefined
                ? personalMatrix.data.isHealth.Vishydha.map((e, index) => (
                  <React.Fragment key={index}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                      {<div style={{ display: "flex", flexDirection: "column" }}>
                        {[{ nomer: props.A2, clas: "-cyan boll" }, {
                          nomer: props.B2!,
                          clas: "-cyan boll"
                        }, { nomer: props.K5!, clas: "forecastCifra" }].map(num => (
                          num.nomer === e.value ? <div
                            style={{ fontSize: 16 }}
                            className={num.clas}
                          >
                            {e.value}
                          </div> : undefined
                        ))}
                      </div>}
                      <div style={{ width: "90%" }}>
                        {e.text.split("\n").map((paragraph: string, innerIndex) => (
                          <React.Fragment key={innerIndex}>
                            {paragraph}
                            <br key={`br-${index}`} />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <br />
                  </React.Fragment>
                ))
                : ""}
              <b>
                Анахата - это четвёртый энергетический центр в теле человека, который расположен в
                области
                сердца. Он связан с любовью, состраданием, эмоциональным благополучием, принятием и
                соединением с другими. Проблемы в этой области могут проявляться как физические
                заболевания,
                так и эмоциональные и психологические состояния. Анахата - отвечают за сердце,
                кровеносная система, органы дыхания, легкие, бронхи, грудной
                отдел позвоночника, рёбра, лопаточная зона спины, грудь.
              </b>
              <br />
              Если у вас возникают проблемы с сердцем, легкими, бронхами или областью грудной клетки, это
              может указывать на дисбаланс или блокировку энергии в анахате. Чувство, которое вы
              описываете, как будто что-то мешает расправить грудь и дышать в полную силу, может быть
              связано с этим.
              <br />
              Также, недостаток энергии для реализации многочисленных планов может указывать на
              неуравновешенность в области анахаты.
              <br />
              Решение проблемы включает в себя работу над укреплением и гармонизацией энергии в анахате.
              Любовь и сострадание к себе и другим, развитие эмоционального благополучия, налаживание
              здоровых отношений и саморазвитие могут способствовать улучшению вашего физического и
              эмоционального состояния.
              <br />
              Существуют различные практики, которые могут помочь в работе с анахатой, такие как медитация
              на сердце, дыхательные упражнения, йога, работа с эмоциями и раскрытие своих чувств. Быть
              открытым и участвовать в некоммерческих деятельностях и благотворительных проектах также
              может сильно поддержать развитие энергии анахаты.
              <br />
              Не забывайте, что важно обратиться к специалистам, таким как кардиологи или пульмонологи,
              для диагностики и лечения специфических проблем с сердцем, легкими или грудной клеткой. Они
              смогут предоставить вам более точную информацию о вашем конкретном состоянии и предложить
              наиболее соответствующее лечение.
              <br />
              {personalMatrix.data.isHealth?.Anaxata !== undefined
                ? personalMatrix.data.isHealth.Anaxata.map((e, index) => (
                  <React.Fragment key={index}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                      {<div style={{ display: "flex", flexDirection: "column" }}>
                        {[{ nomer: props.A3, clas: "-green boll" }, {
                          nomer: props.B3!,
                          clas: "-green boll"
                        }, { nomer: props.K4!, clas: "forecastCifra" }].map(num => (
                          num.nomer === e.value ? <div
                            style={{ fontSize: 16 }}
                            className={num.clas}
                          >
                            {e.value}
                          </div> : undefined
                        ))}
                      </div>}
                      <div style={{ width: "90%" }}>
                        {e.text.split("\n").map((paragraph: string, innerIndex) => (
                          <React.Fragment key={innerIndex}>
                            {paragraph}
                            <br key={`br-${index}`} />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <br />
                  </React.Fragment>
                ))
                : ""}
              <b>
                Манипура - это третья чакра в системе чакр. Она расположена в области живота, отвечает
                за
                регуляцию органов пищеварительной системы, поджелудочной железы, селезенки, печени,
                желчного
                пузыря, тонкого кишечника и центральной части позвоночника. Манипура - отвечают за ЖКТ,
                органы брюшной полости, поджелудочная железа, селезёнка, печень,
                желчный пузырь, тонкий кишечник, центральная часть позвоночника.
              </b>
              <br />
              Возникновение проблем с Манипурой может быть связано с заболеваниями ЖКТ. Нарушения в этой
              чакре могут также отражаться на средней части позвоночника. Это может проявляться на
              поведенческом уровне в виде агрессии, раздражительности и неадекватности.
              <br />
              Кроме того, дисбаланс Манипуры может вызывать изменения в эмоциональной сфере. Равнодушие
              может превращаться в злобу и требовательность. Человек может испытывать неудовлетворенность
              и стремиться удержать то, что считает важным, даже прибегая к незаконным действиям и нарушая
              нормы морали и права.
              <br />
              Если вы испытываете подобные проблемы, рекомендуется обратиться к специалисту в области
              энергетической психологии или квалифицированному терапевту, который может помочь
              восстановить баланс Манипуры и обеспечить здоровое функционирование органов, связанных с
              этой чакрой.
              <br />
              {personalMatrix.data.isHealth?.Manipura !== undefined
                ? personalMatrix.data.isHealth.Manipura.map((e, index) => (
                  <React.Fragment key={index}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                      {<div style={{ display: "flex", flexDirection: "column" }}>
                        {[{ nomer: props.X, clas: "-yellow boll" }, {
                          nomer: props.X!,
                          clas: "-yellow boll"
                        }, { nomer: props.K3!, clas: "forecastCifra" }].map(num => (
                          num.nomer === e.value ? <div
                            style={{ fontSize: 16 }}
                            className={num.clas}
                          >
                            {e.value}
                          </div> : undefined
                        ))}
                      </div>}
                      <div style={{ width: "90%" }}>
                        {e.text.split("\n").map((paragraph: string, innerIndex) => (
                          <React.Fragment key={innerIndex}>
                            {paragraph}
                            <br key={`br-${index}`} />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <br />
                  </React.Fragment>
                ))
                : ""}
              <b>
                Свадхистана - это чакра, или энергетический центр, который отвечает за надпочечники,
                матку и
                яичники, почки, кишечник, предстательную железу у мужчин и поясничный отдел
                позвоночника.
                Проблемы со здоровьем в этих областях могут иметь физическое проявление и влиять на наше
                общее состояние и эмоциональное благополучие.
              </b>
              <br />Когда мы страдаем от заболеваний почек, печени, толстого кишечника, половой сферы,
              надпочечников или поясничного отдела, это может отражаться в нашей эмоциональной сфере.
              Чувство постоянной вины, нехватка любви, раздражительность и отсутствие радости могут быть
              последствием неравновесия в этой энергетической области.
              <br />
              Когда мы ощущаем недостаток плодов от нашей деятельности и начинаем искать выгоду или
              ответственность во всем, это может быть признаком дисбаланса в Свадхистане. Мы можем
              застрять в цикле экономии и выгоды, переживая депрессию из-за отсутствия роста и развития.
              <br />
              Для лучшего состояния здоровья и улучшения эмоционального благополучия рекомендуется
              обратить внимание на свои эмоции и образ жизни. Практики, такие как медитация, йога,
              здоровое питание и поддержание активного образа жизни, могут помочь восстановить баланс
              Свадхистаны. Консультация с врачом также может быть полезной для диагностики и лечения
              физических проблем.
              <br />
              {personalMatrix.data.isHealth?.Svadxistana !== undefined
                ? personalMatrix.data.isHealth.Svadxistana.map((e, index) => (
                  <React.Fragment key={index}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                      {<div style={{ display: "flex", flexDirection: "column" }}>
                        {[{ nomer: props.C2, clas: "-orange boll" }, {
                          nomer: props.D2!,
                          clas: "-orange boll"
                        }, { nomer: props.I5!, clas: "forecastCifra" }].map(num => (
                          num.nomer === e.value ? <div
                            style={{ fontSize: 16 }}
                            className={num.clas}
                          >
                            {e.value}
                          </div> : undefined
                        ))}
                      </div>}
                      <div style={{ width: "90%" }}>
                        {e.text.split("\n").map((paragraph: string, innerIndex) => (
                          <React.Fragment key={innerIndex}>
                            {paragraph}
                            <br key={`br-${index}`} />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <br />
                  </React.Fragment>
                ))
                : ""}
              <br />
              <b>
                Муладхара - это чакра, которая отвечает за мочеполовую систему, нижние конечности,
                толстый
                кишечник, копчик, крестец и ноги. Проблемы со здоровьем в этих областях могут иметь
                физическое проявление и отразиться на нашей жизненной ситуации и эмоциональном
                состоянии.
              </b>
              <br />
              Когда у нас возникают проблемы с ногами, мочеполовой системой или областью крестца, это
              может проявиться на физическом уровне. Ощущение усталости, неудовлетворенности и отчаяния
              может быть результатом неравновесия в этой энергетической области.
              <br />
              В жизненном плане, дисбаланс в Муладхаре может проявляться через материальный голод, чувство
              крушения жизни, постоянную нехватку и ощущение брошенности и недооцененности. Мы можем
              испытывать усталость и чувство безнадежности в отношении текущей ситуации.
              <br />
              Для улучшения состояния здоровья и эмоционального благополучия, рекомендуется обратить
              внимание на свои эмоции и физическое самочувствие. Постоянное движение и физическая
              активность, уход за ногами и мочеполовой системой, практика осознанности и работа над
              устранением материального голода могут помочь достичь равновесия в Муладхаре. Консультация с
              врачом также может быть полезной для диагностики и лечения физических проблем.
              <br />
              {personalMatrix.data.isHealth?.Muladxara !== undefined
                ? personalMatrix.data.isHealth.Muladxara.map((e, index) => (
                  <React.Fragment key={index}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                      {<div style={{ display: "flex", flexDirection: "column" }}>
                        {[{ nomer: props.D, clas: "-red boll" }, {
                          nomer: props.C!,
                          clas: "-red boll"
                        }, { nomer: props.K1!, clas: "forecastCifra" }].map(num => (
                          num.nomer === e.value ? <div
                            style={{ fontSize: 16 }}
                            className={num.clas}
                          >
                            {e.value}
                          </div> : undefined
                        ))}
                      </div>}
                      <div style={{ width: "90%" }}>
                        {e.text.split("\n").map((paragraph: string, innerIndex) => (
                          <React.Fragment key={innerIndex}>
                            {paragraph}
                            <br key={`br-${index}`} />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <br />
                  </React.Fragment>
                ))
                : ""}
              <b>
                Общее энерго поле - это кровеносная система, нервная система, лимфатическая система,
                имунная система, те органы, которые находятся по всему организму, общий сбой работы
                организма
              </b>
              <br />
              {personalMatrix.data.isHealth?.isHealthResult !== undefined
                ? personalMatrix.data.isHealth.isHealthResult.map((e, index) => (
                  <React.Fragment key={index}>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                      {<div style={{ display: "flex", flexDirection: "column" }}>
                        {[{ nomer: props.T2, clas: "forecastCifra" }, {
                          nomer: props.T1!,
                          clas: "forecastCifra"
                        }, { nomer: props.T3!, clas: "forecastCifra" }].map(num => (
                          num.nomer === e.value ? <div
                            style={{ fontSize: 16 }}
                            className={num.clas}
                          >
                            {e.value}
                          </div> : undefined
                        ))}
                      </div>}
                      <div style={{ width: "90%" }}>
                        {e.text.split("\n").map((paragraph: string, innerIndex) => (
                          <React.Fragment key={innerIndex}>
                            {paragraph}
                            <br key={`br-${index}`} />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <br />
                  </React.Fragment>
                ))
                : ""}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
      {accordion(
        "Генетические заболевания",
        [
          {
            paragraph: "",
            value: personalMatrix.data.isGeneticDiseases,
            colorBall: "-white boll"
          }
        ],
        true
      )}
      {accordion(
        "Желания сердца",
        [
          {
            paragraph: "",
            value: personalMatrix.data.isHeartDesire,
            colorBall: "-green boll"
          }
        ],
        true
      )}
      {accordion(
        "Предназначение",
        [
          {
            paragraph: "Предназначение (20-40 лет)",
            value: personalMatrix.data.isPurpose?.isPurpose20_40,
            colorBall: "borderMatrix boll"
          },
          {
            paragraph: "Предназначение (40-60 лет)",
            value: personalMatrix.data.isPurpose?.isPurpose40_60,
            colorBall: "borderMatrix boll"
          },
          {
            paragraph: "Предназначение Общее",
            value: personalMatrix.data.isPurpose?.isGeneralPurpose,
            colorBall: "borderMatrix boll"
          }
        ],
        true
      )}
      {accordion(
        "Код личной силы",
        [
          {
            paragraph: "Личная сила (Зона комфорта)",
            value: personalMatrix.data.isPersonalPowerCode?.isPersonalPowerComfortZone,
            colorBall: "-yellow boll"
          },
          {
            paragraph: "Сила рода",
            value: personalMatrix.data.isPersonalPowerCode?.isPowerOfTheFamily,
            colorBall: "-white boll"
          },
          {
            paragraph: "Общее",
            value: personalMatrix.data.isPersonalPowerCode?.isGeneralPowerCode,
            colorBall: "-white boll"
          }
        ],
        true
      )}
      {accordion(
        "Отношения и любовь",
        [
          {
            paragraph: `Отношения у ${
              props.gender === "M" ? "мужчин" : "женщин"
            }`,
            value: personalMatrix.data.isLove?.isLoveMenOrWomen,
            nums: [{ nomer: props.D2!, clas: "-orange boll" }, { nomer: props.L!, clas: "-white boll" }]
          },
          {
            paragraph: "Характер партнёра",
            value: personalMatrix.data.isLove?.isCharacterLoveMenOrWomen,
            nums: [{ nomer: props.D2!, clas: "-orange boll" }, { nomer: props.L!, clas: "-white boll" }]
          },
          {
            paragraph: "Баланс",
            value: personalMatrix.data.isLove?.loveTotal,
            colorBall: "-white boll"
          }
        ],
        true
      )}

      <Accordion style={{
        backgroundColor: "rgba(240, 213, 253, 1)",
        marginBottom: 5,
        borderRadius: 10,
        color: "rgba(46, 32, 81, 1)"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {personalMatrix.data?.isProgram !== undefined ? (
              <b style={{
                display: "flex",
                fontSize: 24,
                fontFamily: "Golos",
                color: "rgba(135, 102, 173, 1)"
              }}>Программы</b>
            ) : (
              <b style={{
                display: "flex",
                fontSize: 24,
                alignItems: "center",
                fontFamily: "Golos",
                color: "rgba(135, 102, 173, 1)"
              }}>
                {<LockIcon style={{ color: "rgba(135, 102, 173, 1)", marginRight: "15px" }} />} Программы
              </b>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {personalMatrix.data?.isProgram === undefined ? (
            <React.Fragment>
              <p style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 24,
                fontFamily: "Golos",
                marginBottom: 20,
                color: "rgba(135, 102, 173, 1)"
              }}>
                Будет доступно после оформления одного из тарифов
              </p>
              <Anchor smooth to={"/#tarif"} className={"batonStandart"}>
                ТАРИФЫ
              </Anchor>
            </React.Fragment>
          ) : (
            <Typography>
              {personalMatrix.data?.isProgram.map((e, index) => (
                <React.Fragment key={index}>
                  <b style={{ color: "rgba(135, 102, 173, 1)", fontSize: 24 }}>{e.title}</b>
                  <br key={`br-${index}`} />
                  {e.text.split("\n").map((paragraph: string, innerIndex) => (
                    <React.Fragment key={innerIndex}>
                      {paragraph}
                      <br key={`br-${index}`} />
                    </React.Fragment>
                  ))}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {accordion(
        "Деньги",
        [
          {
            paragraph: "Профессии и направление деятельности",
            value: personalMatrix.data.isMoney?.moneyLineOfActivity,
            colorBall: "-white boll"
          },
          {
            paragraph: "Для финансового благополучия важно",
            value: personalMatrix.data.isMoney?.moneySuccess,
            nums: [{ nomer: props.G4!, clas: "-white boll" }, {
              nomer: props.C2!,
              clas: "-orange boll"
            }, { nomer: props.M!, clas: "-white boll" }]
          },
          {
            paragraph: "Как раскрыть денежный поток",
            value: personalMatrix.data.isMoney?.moneyFlow,
            nums: [{ nomer: props.C, clas: "-red boll" }, {
              nomer: props.C1!,
              clas: "-white boll"
            }, { nomer: props.C2!, clas: "-orange boll" }]
          }
        ],
        true
      )}
      {accordion(
        "Родители",
        [
          {
            paragraph: "Родовые программы по мужской линии",
            value: personalMatrix.data.isParents?.parentMenLine,
            nums: [{ nomer: props.E, clas: "-white boll" }, {
              nomer: props.E1!,
              clas: "-white boll" }, {
              nomer: props.E2!,
              clas: "-white boll" },
              { nomer: props.EG!, clas: "borderMatrix boll" }, {
                nomer: props.G!,
                clas: "-white boll" }, {
                nomer: props.G1!,
                clas: "-white boll" },
              {
                nomer: props.G2!,
                clas: "-white boll" }]
          },
          {
            paragraph: "Родовые программы по женской линии",
            value: personalMatrix.data.isParents?.parentWomenLine,
            nums: [{ nomer: props.F, clas: "-white boll" }, {
              nomer: props.F1!,
              clas: "-white boll" }, {
              nomer: props.F2!,
              clas: "-white boll" },
              { nomer: props.FH!, clas: "borderMatrix boll" }, {
                nomer: props.H!,
                clas: "-white boll" }, {
                nomer: props.H1!,
                clas: "-white boll" },
              {
                nomer: props.H2!,
                clas: "-white boll" }]
          },
          {
            paragraph: "Обиды на родителей",
            value: personalMatrix.data.isParents?.parentResentment,
            nums: [{ nomer: props.A, clas: "-purple boll" }, {
              nomer: props.A1!,
              clas: "-blue boll"
            }, { nomer: props.A2!, clas: "-cyan boll" }]
          }
        ],
        true
      )}
      {accordion(
        "Дети",
        [
          {
            paragraph: "",
            value: personalMatrix.data?.isChildren,
            nums: [{ nomer: props.A, clas: "-purple boll" }, {
              nomer: props.A1!,
              clas: "-blue boll"
            }, { nomer: props.A2!, clas: "-cyan boll" }]
          }
        ],
        true
      )}

      {accordion(
        "Руководство",
        [
          {
            paragraph: "",
            value: personalMatrix.data?.isManagement,
            nums: [{ nomer: props.A, clas: "-purple boll" }, {
              nomer: props.B!,
              clas: "-purple boll"
            }, { nomer: props.X!, clas: "-yellow boll" }]
          }
        ],
        true
      )}
      {accordion(
        "Бренд Личности (NEW)",
        [
          {
            paragraph: "Самовыражение",
            value: personalMatrix.data.isBrandPersonality?.isBrandPersonalitySelfExpression,
            nums: [{ nomer: props.X!, clas: "-yellow boll" }, {
              nomer: props.A!,
              clas: "-purple boll"
            }]
          },
          {
            paragraph: "Визуал",
            value: personalMatrix.data.isBrandPersonality?.isBrandPersonalityVisual,
            nums: [{ nomer: props.X!, clas: "-yellow boll" }, {
              nomer: props.A!,
              clas: "-purple boll"
            }, {
              nomer: props.B!,
              clas: "-purple boll"
            }]
          },
          {
            paragraph: "Темы для позиционирования",
            value: personalMatrix.data.isBrandPersonality?.isBrandPersonalityThemesForPositioning,
            nums: [{ nomer: props.B2!, clas: "-cyan boll" }, {
              nomer: props.C2!,
              clas: "-orange boll"
            }, {
              nomer: props.M!,
              clas: "-white boll"
            }]
          }
        ],
        true
      )}
      <Accordion style={{
        backgroundColor: "rgba(240, 213, 253, 1)",
        marginBottom: 5,
        borderRadius: 10,
        color: "rgba(135, 102, 173, 1)"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <b style={{ fontSize: 24, fontFamily: "Golos" }}>Прогнозы по годам</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ width: "100%", padding: 0, margin: 0 }}>
          <div>
            <ForecastForTheYear
              repeat={props.repeat}
              yes={props.yes}
              yaer={yearHandler}
              age={props.age}
              B={props.B}
              D={props.D}
              DHDD={props.DHDD}
              BFBF={props.BFBF}
              BFB={props.BFB}
              DHD={props.DHD}
              BFBBF={props.BFBBF}
              DHDDH={props.DHDDH}
              BF={props.BF}
              DH={props.DH}
              BFFBF={props.BFFBF}
              DHHDH={props.DHHDH}
              BFF={props.BFF}
              DHH={props.DHH}
              BFFF={props.BFFF}
              DHHH={props.DHHH}
              F={props.F}
              H={props.H}
              FCFF={props.FCFF}
              HAHH={props.HAHH}
              FCF={props.FCF}
              HAH={props.HAH}
              FCFFC={props.FCFFC}
              HAHHA={props.HAHHA}
              FC={props.FC}
              HA={props.HA}
              FCCFC={props.FCCFC}
              HAAHA={props.HAAHA}
              FCC={props.FCC}
              HAA={props.HAA}
              FCCC={props.FCCC}
              HAAA={props.HAAA}
              C={props.C}
              A={props.A}
              CGCC={props.CGCC}
              AEAA={props.AEAA}
              CGC={props.CGC}
              AEA={props.AEA}
              CGCCG={props.CGCCG}
              AEAAE={props.AEAAE}
              CG={props.CG}
              AE={props.AE}
              CGGCG={props.CGGCG}
              AEEAE={props.AEEAE}
              CGG={props.CGG}
              AEE={props.AEE}
              CGGG={props.CGGG}
              AEEE={props.AEEE}
              G={props.G}
              E={props.E}
              GDGG={props.GDGG}
              EBEE={props.EBEE}
              GDG={props.GDG}
              EBE={props.EBE}
              GDGGD={props.GDGGD}
              EBEEB={props.EBEEB}
              GD={props.GD}
              EB={props.EB}
              GDDGD={props.GDDGD}
              EBBEB={props.EBBEB}
              GDD={props.GDD}
              EBB={props.EBB}
              GDDD={props.GDDD}
              EBBB={props.EBBB}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
