import React from "react";
import TextField from "@mui/material/TextField";
import {
  emailValidation,
  passValidation
} from "../validations/validationRegistrationForm";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import FormHelperText from "@mui/material/FormHelperText";
import { Checkbox, createTheme, FormControlLabel, Theme, ThemeProvider } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { loginThunk } from "../redux-store/registration-login-auth";
import { useAppDispatch } from "../redux-store/store";
import { Link } from "react-router-dom";
import "./Login.scss";
import { useTheme } from "@mui/material/styles";


const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
      primary: {
        main: "rgba(46, 32, 81, 1)" // цвет текста метки и текста ошибки
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            color: "white",
            "--TextField-brandBorderColor": "rgba(46, 32, 81, 1)",
            "--TextField-brandBorderHoverColor": "rgba(46, 32, 81, 1)",
            "--TextField-brandBorderFocusedColor": "rgba(46, 32, 81, 1)",
            "& label.Mui-focused": {
              color: "rgba(46, 32, 81, 1)",
              fontFamily: "Golos"
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: "rgba(46, 32, 81, 1)",
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)"
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)"
            },
            fontFamily: "Golos"
          }
        }
      }
    }
  });


interface FormRegistration {
  email: string;
  password: string;
  check: boolean;
}

export const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useAppDispatch();
  const outerTheme = useTheme();
  const {
    handleSubmit,
    formState: { errors, isValid },
    register
  } = useForm<FormRegistration>({
    mode: "onBlur"
  });


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<FormRegistration> = (data) => {
    dispatch(
      loginThunk({
        email: data.email.toLowerCase(),
        password: data.password,
        check: data.check
      })
    );
  };

  return (
    <form className={"formLoginRgistr"} onSubmit={handleSubmit(onSubmit)}>
      <span className={"loginSpan"}>Войти в личный кабинет</span>
      <div className={"divLogin"}>
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            className={"formPassAndMail"}
            {...register("email", emailValidation)}
            label="Ваш Email"
            InputLabelProps={{ style: { color: "rgba(46, 32, 81, 1)" } }}
            multiline
            variant="standard"
            error={!!errors.email?.message}
            helperText={errors.email?.message}
          />
        </ThemeProvider>
      </div>
      {!errors.email?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> : undefined}
      <div className={"divLogin"}>
        <FormControl className={"formPassAndMail"} variant="standard">
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              variant={"standard"}
              error={!!errors.password?.message}
              {...register("password", passValidation)}
              type={showPassword ? "text" : "password"}
              InputLabelProps={{ style: { color: "rgba(46, 32, 81, 1)" } }}
              label={"Ваш пароль"}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff style={{ color: "rgba(104, 74, 134, 1)" }} /> :
                      <Visibility style={{ color: "rgba(104, 74, 134, 1)" }} />}
                  </IconButton>
                </InputAdornment>
              }}
            />
          </ThemeProvider>
          {!errors.password?.message ? <span style={{ height: 19.91, display: "block", marginTop: 3 }}></span> :
            <FormHelperText error={!!errors.password?.message}>
              {errors.password?.message}
            </FormHelperText>}
        </FormControl>
      </div>
      <FormControlLabel
        className={"saveMe"}
        control={<Checkbox style={{
          color: "rgba(104, 74, 134, 1)",
          width: 16,
          height: 16,
          backgroundColor: "white",
          margin: "0 10px 0 0",
          padding: 0,
          borderRadius: 0
        }} defaultChecked {...register("check")} />}
        label="Запомнить меня"
      />
      <button
        style={{ width: "90%" }}
        className={`batonStandart + ' ' + ${!isValid ? "dis" : ""}`}
        disabled={!isValid}
        type={"submit"}
      >
        Войти
      </button>
      <Link className={"forgotLink"} to={"/forgot"}>
        Забыли свой пароль?
      </Link>
    </form>
  );
};
