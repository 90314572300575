import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../../redux-store/store";
import { useEffect } from "react";
import { historyCompatibilityMatrix } from "../../../../redux-store/registration-login-auth";
import { Link } from "react-router-dom";
import { calculation, funcCalculation } from "../../../../utils/calc";
import { personalMatrixAPI } from "../../../../API/API";
import { TableWaitSkeleton } from "../../../../feature/TableWaitSkeleton";
import { v4 } from "uuid";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(135, 102, 173, 1)",
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Golos",
    // whiteSpace: 'nowrap'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    backgroundColor: "rgba(240, 213, 253, 1)",
    fontFamily: "Golos",
    whiteSpace: 'nowrap'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

function createData(
  partnerOne: string,
  partnerTwo: string,
  link: JSX.Element,
  pdf: JSX.Element
) {
  return { partnerOne, partnerTwo, link, pdf };
}

export function TableCompatibility() {
  const id = useAppSelector((state) => state.registrationReducer.id);
  const tableWait = useAppSelector((state) => state.errorReducer.tableWait);
  const dispatch = useAppDispatch();
  const matrixSearchHistory = useAppSelector(
    (state) => state.registrationReducer.matrixSearchHistory
  );

  const onClickHandlerCompatibilityPDF = async (
    date1: string,
    date2: string
  ) => {
    const birthdayArray = date1.split("-");
    let A = calculation(parseInt(birthdayArray[2]));
    let B = calculation(parseInt(birthdayArray[1]));
    let C = calculation(parseInt(birthdayArray[0]));
    let D = calculation(A + B + C);
    let X = calculation(A + B + C + D);
    let F = calculation(B + C);
    let H = calculation(D + A);
    let G = calculation(C + D);
    let E = calculation(A + B);
    const birthdayArray1 = date2.split("-");
    let Ac = calculation(parseInt(birthdayArray1[2]));
    let Bc = calculation(parseInt(birthdayArray1[1]));
    let Cc = calculation(parseInt(birthdayArray1[0]));
    let Dc = calculation(Ac + Bc + Cc);
    let Xc = calculation(Ac + Bc + Cc + Dc);
    let Fc = calculation(Bc + Cc);
    let Hc = calculation(Dc + Ac);
    let Gc = calculation(Cc + Dc);
    let Ec = calculation(Ac + Bc);
    // Compatibility
    let Bd = calculation(B + Bc);
    let Fd = calculation(F + Fc);
    let Cd = calculation(C + Cc);
    let Gd = calculation(G + Gc);
    let Dd = calculation(D + Dc);
    let Hd = calculation(H + Hc);
    let Ad = calculation(A + Ac);
    let Ed = calculation(E + Ec);
    let Xd = calculation(X + Xc);
    let B2d = calculation(Bd + Xd);
    let B1d = calculation(Bd + B2d);
    let C2d = calculation(Cd + Xd);
    let C1d = calculation(Cd + C2d);
    let D2d = calculation(Dd + Xd);
    let D1d = calculation(Dd + D2d);
    let G4d = calculation(C2d + D2d);
    let Ld = calculation(D2d + G4d);
    let Md = calculation(G4d + C2d);
    let LNd = calculation(Bd + Dd);
    let LZd = calculation(Ad + Cd);
    let LP1d = calculation(LNd + LZd);
    let LOd = calculation(Ed + Gd);
    let LMd = calculation(Fd + Hd);
    let YMd = calculation(LOd + LMd);
    let RRMd = calculation(LP1d + YMd);
    try {
      let response = await personalMatrixAPI.getPdfCompatibility(
        funcCalculation([Ad]),
        funcCalculation([Bd, B1d, B2d]),
        funcCalculation([Cd, C1d, C2d]),
        funcCalculation([Dd, D1d, D2d]),
        funcCalculation([Ed, Fd, Gd, Hd]),
        funcCalculation([G4d, Md, C2d]),
        funcCalculation([LNd, LZd, LP1d]),
        funcCalculation([LOd, LMd, YMd]),
        funcCalculation([RRMd]),
        funcCalculation([G4d, Ld, D2d]),
        funcCalculation([Xd]),
        date1,
        date2
      );
      const blob = await new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${date1}/${date2}`;
      link.click();
      link.remove();
      URL.revokeObjectURL(url);
    } catch (e) {
      console.log(e);
    }
  };

  const rows = matrixSearchHistory?.compatibility?.map((e) =>
    createData(
      e.datePartnerOne,
      e.datePartnerTwo,
      <Link
        className={"batonStandartRow"}
        to={`/matrixcompatibility?date=${e.datePartnerOne}&date1=${e.datePartnerTwo}&id=${id}`}
      >
        Рассчитать
      </Link>,
      <span
        className={"batonStandartRow"}
        onClick={() =>
          onClickHandlerCompatibilityPDF(e.datePartnerOne, e.datePartnerTwo)
        }
      >
        Скачать
      </span>
    )
  ).reverse();

  useEffect(() => {
    dispatch(historyCompatibilityMatrix());
  }, []);

  return (
    <>{rows.length === 0 ? <span className={"spanHistoryNone"}>Ваша история поисков пуста</span> :
      <TableContainer component={Paper}>
        <Table sx={{ width: "100% !important" }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={"tableRow"} align="center">
                Дата рождения первого партнера
              </StyledTableCell>
              <StyledTableCell className={"tableRow"} align="center">
                Дата рождения второго партнера
              </StyledTableCell>
              <StyledTableCell className={"tableRow"} align="center">
                Повторный расчет
              </StyledTableCell>
              <StyledTableCell className={"tableRow"} align="center">
                Документ
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {tableWait ? (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  <TableWaitSkeleton />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <TableWaitSkeleton />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <TableWaitSkeleton />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <TableWaitSkeleton />
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          ) : (
            <TableBody>
              {rows?.map((row) => (
                <StyledTableRow key={v4()}>
                  <StyledTableCell
                    className={"tableRow"}
                    component="th"
                    scope="row"
                  >
                    {row.partnerOne.split("-").reverse().join("-")}
                  </StyledTableCell>
                  <StyledTableCell className={"tableRow"} align="center">
                    {row.partnerTwo.split("-").reverse().join("-")}
                  </StyledTableCell>
                  <StyledTableCell className={"tableRow"} align="center">
                    {row.link}
                  </StyledTableCell>
                  <StyledTableCell className={"tableRow"} align="center">
                    {row.pdf}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>}
    </>
  );
}
