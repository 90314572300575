import * as React from "react";
import Stack from "@mui/material/Stack";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./DatePickers.scss";
import { FormGroup } from "@material-ui/core";
import { useAppSelector } from "../redux-store/store";
import wave from "../wave.png";
import iconmangrey from "../iconmangrey.png";
import iconmencolor from "../iconmabcolor.png";
import iconwoomencolor from "../iconwoomencolor.png";
import iconwoomengrey from "../iconwoomengrey.png";
import babyboygrey from "../babyboygrey.png";
import babyboygcolor from "../babyboycolor.png";
import babygirlgrey from "../babygirlgrey.png";
import babygirlcolor from "../babygirlcolor.png";
export function DatePickers() {
  const [value, setValue] = useState<string>("");
  const [value1, setValue1] = useState<string>("");
  const [matrix, setMatrix] = useState<string>("personal");
  const [name, setName] = useState<string>("");
  const [check, setCheck] = useState<boolean>(false);
  const id = useAppSelector((state) => state.registrationReducer.id);
  const elementRef = useRef<HTMLImageElement>(null);
  const elementRefDiv = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0)
  const [heightDiv, setHeightDiv] = useState(0)

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (elementRef.current && elementRefDiv.current) {
      const heightImg = elementRef.current.getBoundingClientRect().height;
      const heightDivv = elementRefDiv.current.getBoundingClientRect().height;
      setHeight(heightImg)
      setHeightDiv(heightDivv)
    }
  }, [elementRef.current, elementRefDiv.current]);
  const onChangeHandlerName = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(e.currentTarget.value);
  };
  const onCheckMan = () => {
      setCheck(true);
  };
  const onCheckWomen = () => {
    setCheck(false);
  };
  const onChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let inputDate = e.currentTarget.value.replace(/\D/g, "");
    if (inputDate.length > 8) {
      inputDate = inputDate.slice(0, 8);
    }
    if (inputDate.length >= 5) {
      inputDate = `${inputDate.slice(0, 2)}-${inputDate.slice(
        2,
        4
      )}-${inputDate.slice(4)}`;
    } else if (inputDate.length >= 3) {
      inputDate = `${inputDate.slice(0, 2)}-${inputDate.slice(2)}`;
    }
    setValue(inputDate);
  };
  const onChangeHandler1 = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let inputDate = e.currentTarget.value.replace(/\D/g, "");
    if (inputDate.length > 8) {
      inputDate = inputDate.slice(0, 8);
    }
    if (inputDate.length >= 5) {
      inputDate = `${inputDate.slice(0, 2)}-${inputDate.slice(
        2,
        4
      )}-${inputDate.slice(4)}`;
    } else if (inputDate.length >= 3) {
      inputDate = `${inputDate.slice(0, 2)}-${inputDate.slice(2)}`;
    }
    setValue1(inputDate);
  };
  const onClickHandlerMatrix = (value: string) => {
    setMatrix(value);
  };
  return (
    <div style={{display: 'flex', flexDirection: 'column', marginTop: '25%'}}>
      <span className={'raschetSpan'}>расчет матрицы</span>
      <div className={"matrixSearch"} ref={elementRefDiv}>

        <span
          onClick={() => onClickHandlerMatrix("compatibility")}
          className={`matrixSpanSearch ${
            matrix === "compatibility" ? "activeMatrix" : ""
          }`}
        >
            Матрица {width < 480 ? <br/> : ''} совместимости
          </span>
        <span
          onClick={() => onClickHandlerMatrix("children")}
          className={`matrixSpanSearch ${
            matrix === "children" ? "activeMatrix" : ""
          }`}
        >
            Детская {width < 480 ? <br/> : ''} матрица
          </span>
        <span
          onClick={() => onClickHandlerMatrix("personal")}
          className={`matrixSpanSearch ${
            matrix === "personal" ? "activeMatrix" : ""
          }`}
        >
            Персональная {width < 480 ? <br/> : ''} матрица
          </span>

      </div>
      <section className={"sectionDate"} id={"sectionDateScroll"}>
        <div className={"backImageDate"}></div>
        <div className={"textAnddatePick"}>
          <div className={"personalText"}>
            <p>
              <b>Персональная матрица</b> — это уникальная конструкция, возникающая в результате сложных расчетов,
              основанных на
              данных о вашей дате рождения. Она позволяет установить, какие энергии в вашей жизни преобладают в
              положительном аспекте, а где, напротив, начинают скатываться в негатив. Дополнительно, эта матрица
              предлагает рекомендации о том, что стоит делать и чего лучше избегать, чтобы достичь гармонии и успеха.
            </p>
          </div>
          <div className={'divString'}>
            <FormGroup>
              {matrix === "personal" || matrix === "children" ? (
                <input style={{
                  marginBottom: "20px"
                }} onChange={onChangeHandlerName} type="text" placeholder={'Имя'} className={"textMatrix"} />
              ) : undefined}
            </FormGroup>
            {matrix === "personal" ? (
              <Stack
                className={"matrixStack"}
                component="form"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center"
                }}
                noValidate
                spacing={3}
              >
                <input value={value} onChange={onChangeHandler} type="text" placeholder={"Дата рождения"}
                       className={"textMatrix"} />
                <div className={"iconMatrixGenderDiv"}>
                  <span>Выберите пол:</span>
                  <div className={"iconMatrixGender"}>
                    <img onClick={onCheckMan} src={check ? iconmencolor : iconmangrey} alt="" />
                    <img onClick={onCheckWomen} src={!check ? iconwoomencolor : iconwoomengrey} alt="" />
                  </div>
                </div>
                <Link
                  aria-disabled
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "#8766AD",
                    fontFamily: "Golos",
                    fontSize: 18,
                    fontWeight: "bold"
                  }}
                  className={`baton ${
                    value.length !== 10 || name.length === 0 ? "disabled-link" : ""
                  }`}
                  to={`/matrix?date=${value.split("-").reverse().join("-")}&male=${check ? "M" : "W"}&id=${id}&name=${name}&child=`}
                >
                  Рассчитать
                </Link>
              </Stack>
            ) : matrix === "children" ? (
              <Stack
                className={"matrixStack"}
                component="form"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center"
                }}
                noValidate
                spacing={3}
              >
                <input onChange={onChangeHandler} value={value} type="text" placeholder={"Дата рождения ребенка"}
                       className={"textMatrix"} />
                <div className={"iconMatrixGenderDiv"}>
                  <span>Выберите пол ребенка:</span>
                  <div className={"iconMatrixGender"}>
                    <img onClick={onCheckMan} src={check ? babyboygcolor : babyboygrey} alt="" />
                    <img onClick={onCheckWomen} src={!check ? babygirlcolor : babygirlgrey} alt="" />
                  </div>
                </div>
                <Link
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "#8766AD",
                    fontFamily: "Golos",
                    fontSize: 18,
                    fontWeight: "bold"
                  }}
                  className={`baton ${
                    value.length !== 10 || name.length === 0 ? "disabled-link" : ""
                  }`}
                  to={`/matrix?date=${value.split("-").reverse().join("-")}&male=${check ? "M" : "W"}&id=${id}&name=${name}&child=true`}
                >
                  Рассчитать
                </Link>
              </Stack>
            ) : (
              <Stack
                className={"matrixStack"}
                component="form"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center"
                }}
                noValidate
                spacing={3}
              >
                <input onChange={onChangeHandler} value={value} type="text" placeholder={"Дата рождения партнера 1"}
                       className={"textMatrix"} />

                <input onChange={onChangeHandler1} value={value1} type="text" placeholder={"Дата рождения партнера 2"}
                       className={"textMatrix"} />
                <Link
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "#8766AD",
                    fontFamily: "Golos",
                    fontSize: 18,
                    fontWeight: "bold"
                  }}
                  className={`baton ${
                    value.length !== 10 || value1.length !== 10
                      ? "disabled-link"
                      : ""
                  }`}
                  to={`/matrixcompatibility?date=${value.split("-").reverse().join("-")}&date1=${value1.split("-").reverse().join("-")}&id=${id}`}
                >
                  Рассчитать
                </Link>
              </Stack>
            )}
          </div>
        </div>
        <img ref={elementRef} style={{ bottom: -height, zIndex: 1 }} src={wave} alt="Волна" className={"wavePng"} />
      </section>
    </div>
  );
}
